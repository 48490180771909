/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { useDispatch, useSelector } from 'react-redux';

// import { mapAction } from 'core/map';
import { audioAction } from 'core/audio';
import { audioTimeFormat } from 'core/helper/util';

import { LinkBtn } from 'components/global/Button';
import { Play, Pause } from 'components/icon/Svg';
// import Close from 'components/icon/Close';

function AudioBar({ audioData }) {
  const dispatch = useDispatch();
  const { fetchedAudios } = useSelector((state) => state.audio);
  const selectedAudio = {
    ...fetchedAudios.find((audio) => audio.podcast === audioData.podcast),
  };
  const { podcast, playerState, time, duration = '00:00' } = selectedAudio;

  const handlePlay = () => dispatch(audioAction.setPlayerState(selectedAudio));

  // const handleClose = () => {
  //   dispatch(audioAction.setPlayerState(selectedAudio, 'pause'));
  //   dispatch(mapAction.toggleDetail(false));
  // };

  return (
    <Wrap>
      <PlayBtn onClick={handlePlay}>
        {podcast === audioData.podcast ? (
          playerState !== 'play' || playerState === 'init' ? (
            <Play />
          ) : (
            <Pause />
          )
        ) : (
          <Play />
        )}
      </PlayBtn>

      <Time>
        {`${
          podcast === audioData.podcast ? audioTimeFormat(time) : '00:00'
        } / ${duration}`}
      </Time>

      {/* <InfoClose onClick={handleClose} /> */}
    </Wrap>
  );
}

AudioBar.propTypes = {
  audioData: PropTypes.shape(),
};

AudioBar.defaultProps = {
  audioData: {},
};

export default AudioBar;

const Wrap = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 36px;
  padding: 8px;
  margin-bottom: 8px;
  font-size: 12px;
  line-height: 18px;
  border: 1px solid #979797;
  @media (min-width: ${({ theme }) => theme.breakpoint.lg}) {
    margin-bottom: 0;
  }
`;

const PlayBtn = styled(LinkBtn)`
  margin-right: 12px;
  & > svg {
    width: 20px;
    height: 20px;
    path {
      fill: ${({ theme }) => theme.font};
    }
  }
`;

// const InfoClose = styled(Close)`
//   position: absolute;
//   top: 0;
//   right: 0;
//   width: 36px;
//   height: 36px;
//   & > i {
//     &::before,
//     &::after {
//       background-color: ${({ theme }) => theme.font};
//     }
//   }
// `;

const Time = styled.span``;
