import { detectDeviceOs } from 'core/helper/util';

const searchParams = new URLSearchParams(window.location.search);

const env = {
  fetchLimit: 1000,
  indexUrl: 'https://sunrisemedium.com/',
  routerBaseName: process.env.REACT_APP_BASE_PATH,
  account: process.env.REACT_APP_ACCOUNT,
  password: process.env.REACT_APP_PASSWORD,
  mapApiKey: process.env.REACT_APP_MAP_API_KEY,
  runDev: process.env.REACT_APP_ENV,
  audio_api:
    window.location.hostname === 'soundof.sense-info.co'
      ? process.env.REACT_APP_AUDIO_API_DEV
      : process.env.REACT_APP_AUDIO_API_PROD,
  functions_api:
    process.env.REACT_APP_ENV === 'local'
      ? process.env.REACT_APP_API_PATH
      : '/api/',
  localApiKey: '210715001',
  isIframe: searchParams.get('iframe') === 'yes',
  isGroups: window.location.pathname.includes('groups'),
  currentOs: detectDeviceOs(),
};

export default env;
