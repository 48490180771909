import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';

import env from 'config/env';

import Image from 'components/global/Image';

function Loading({ active }) {
  const { onload } = useSelector((state) => state.global);

  return (
    <Wrap
      className={`${active ? 'active' : ''}${
        onload ? ' j-onload' : ' j-onload'
      }`}
    >
      <Image
        className={onload ? 'j-hide' : 'j-hide'}
        src={`${env.routerBaseName}/asset/image/icon/logo.svg`}
      />
      <Image
        className={onload ? '' : ''}
        src={`${env.routerBaseName}/asset/image/icon/loading.svg`}
      />
    </Wrap>
  );
}

Loading.propTypes = {
  active: PropTypes.bool,
};

Loading.defaultProps = {
  active: false,
};

const Wrap = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  z-index: ${({ theme }) => theme.zIndex.loading};
  background-color: rgba(3, 3, 3, 0.7);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease-in;
  & > img {
    width: 25%;
    max-width: 300px;
  }
  &.active {
    opacity: 1;
    pointer-events: auto;
  }
  &.j-onload {
    & > img {
      height: 27px;
      width: 27px;
      padding: 5px;
      animation: 0.86s cubic-bezier(0.4, 0.15, 0.6, 0.85) infinite
        spinnerRotateAnimation;
    }
  }

  @keyframes spinnerRotateAnimation {
    to {
      transform: rotate(-360deg);
    }
  }
`;

export default Loading;
