import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import env from 'config/env';

import Image from 'components/global/Image';
import Close from 'components/icon/Close';

function ImgZoomUp({ url, title, description, onClose }) {
  const handleEsc = (e) => {
    if (e.keyCode === 27) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleEsc, false);
    return () => {
      document.removeEventListener('keydown', handleEsc, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrap className={url ? 'active' : ''} onClick={onClose}>
      <WrapClose onClick={onClose} />

      <Image
        src={
          env.runDev === 'local'
            ? 'https://via.placeholder.com/240x180/FCEA10/000000'
            : url
        }
        alt={title}
      />

      {title ? <Title>{title}</Title> : null}

      {description ? <Description>{description}</Description> : null}
    </Wrap>
  );
}

ImgZoomUp.propTypes = {
  url: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

ImgZoomUp.defaultProps = {
  url: undefined,
  title: undefined,
  description: undefined,
};

const Wrap = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  padding: 0 20px 8px;
  overflow-y: auto;
  z-index: ${({ theme }) => theme.zIndex.imgZoomUp};
  background-color: rgba(3, 3, 3, 1);
  opacity: 0;
  pointer-events: none;
  transition: opacity ease-in 0.3s;
  &.active {
    opacity: 1;
    pointer-events: auto;
    & > img {
      opacity: 1;
    }
  }
  & > img {
    aspect-ratio: 16 / 9;
    object-fit: cover;
    margin: 0 auto 20px;
    width: 100%;
    opacity: 0;
  }
  @media (orientation: portrait) {
    //豎屏CSS
    padding-top: 84px;
  }
  @media (orientation: landscape) {
    //橫屏CSS
    padding-top: 16px;
    text-align: center;
    & > img {
      width: auto;
      max-width: calc(100% - 40px);
      max-height: 70%;
      height: 70%;
      margin: 0 auto 20px;
    }
  }
  @media (min-width: ${({ theme }) => theme.breakpoint.lg}) {
    & > img {
      width: 100%;
      max-width: 800px;
      height: auto;
    }
    padding: 112px 20px 24px;
  }
`;

const Title = styled.h5`
  font-size: 18px;
  line-height: 27px;
  margin: 0 0 16px;
  @media (min-width: ${({ theme }) => theme.breakpoint.lg}) {
    width: 800px;
    margin: 0 auto 16px;
    text-align: left;
  }
`;

const Description = styled.p`
  font-size: 12px;
  line-height: 18px;
  margin: 0;
  @media (min-width: ${({ theme }) => theme.breakpoint.lg}) {
    width: 800px;
    margin: 0 auto 16px;
    text-align: left;
  }
`;

const WrapClose = styled(Close)`
  position: absolute;
  top: 20px;
  right: 10px;
  > i {
    &::before,
    &::after {
      background-color: ${({ theme }) => theme.font};
    }
  }
  @media (min-width: ${({ theme }) => theme.breakpoint.lg}) {
    top: 20px;
    right: 20px;
    width: 48px;
    height: 48px;
  }
`;

export default ImgZoomUp;
