import { call, takeLatest, fork, put, select } from 'redux-saga/effects';

import env from 'config/env';
import globalAction from 'core/global/action';
import { audioTimeFormat } from 'core/helper/util';
import audioAction from './action';

export function* fetchAudio({ payload }) {
  const { playingAudio, fetchedAudios } = yield select((state) => state.audio);
  const selectedAudio = { ...payload };
  let currentFetchedAudios = structuredClone(fetchedAudios).filter((audio) =>
    [playingAudio, selectedAudio.podcast].includes(audio.podcast)
  );

  if (
    !fetchedAudios.length ||
    fetchedAudios.every((audio) => audio.podcast !== selectedAudio.podcast)
  ) {
    if (env.isIframe) {
      selectedAudio.podcastUrl =
        env.runDev === 'local'
          ? `https://bitdash-a.akamaihd.net/content/sintel/hls/playlist.m3u8`
          : `${env.audio_api}/${payload.m3u8_uri}playlist.m3u8`;
    } else {
      selectedAudio.podcastUrl = `https://soundcloud.com${payload.podcast}`;
    }

    selectedAudio.playerState = 'init';
    selectedAudio.duration = '00:00';
    selectedAudio.time = 0;
    currentFetchedAudios = [...currentFetchedAudios, selectedAudio];

    yield put({ type: globalAction.FETCH_REQUEST });
    yield put(audioAction.setFetchedAudio(currentFetchedAudios));
  }
}

export function* setPlayerState({ payload }) {
  const { audioObj, playerState } = payload;
  const newAudioObj = { ...audioObj };

  if (playerState) {
    newAudioObj.playerState = playerState;
  } else {
    newAudioObj.playerState =
      audioObj.playerState === 'play' ? 'pause' : 'play';
  }

  yield call(changeAudioState, newAudioObj);

  if (newAudioObj.playerState === 'play') {
    yield put(audioAction.setPlayingAudio(newAudioObj.podcast));
  } else {
    yield put(audioAction.setPlayingAudio(''));
  }
}

export function* handleDuration({ payload }) {
  const { currentAudio, duration } = payload;
  const currentDuration = audioTimeFormat(duration);
  currentAudio.duration = currentDuration;

  yield call(changeAudioState, currentAudio);
}

export function* handleProgress({ payload }) {
  const { currentAudio, playedSeconds, played, loadedSeconds, loaded } =
    payload;

  currentAudio.time = playedSeconds;
  currentAudio.played = played;
  currentAudio.loadedSeconds = loadedSeconds;
  currentAudio.loaded = loaded;

  yield call(changeAudioState, currentAudio);
}

export function* handlePause({ payload }) {
  const currentAudio = { ...payload };

  if (currentAudio.playerState === 'play') {
    currentAudio.playerState = 'pause';

    yield call(changeAudioState, currentAudio);
  }
}

export function* handlePlay({ payload }) {
  const { currentAudio, played } = payload;

  if (!played) {
    yield put(globalAction.sendGtagEvent('tryto', 'related', currentAudio.id));
  }

  if (currentAudio.playerState === 'pause') {
    currentAudio.playerState = 'play';

    yield call(changeAudioState, currentAudio);
  }
}

export function* changeAudioState(audioObj) {
  const { fetchedAudios } = yield select((state) => state.audio);
  const currentAudios = [...fetchedAudios].map((audio) => {
    if (audio.podcast === audioObj.podcast) {
      return { ...audioObj };
    }
    return { ...audio };
  });

  yield put(audioAction.setFetchedAudio(currentAudios));
}

//-------------------------------------
//  WATCHERS
//-------------------------------------

export function* watchFetchAudio() {
  yield takeLatest(audioAction.FETCH_AUDIO, fetchAudio);
}

export function* watchSetPlayerState() {
  yield takeLatest(audioAction.SET_PLAYER_STATE, setPlayerState);
}

export function* watchHandleDuration() {
  yield takeLatest(audioAction.HANDLE_DURATION, handleDuration);
}

export function* watchHandleProgress() {
  yield takeLatest(audioAction.HANDLE_PROGRESS, handleProgress);
}

export function* watchHandlePause() {
  yield takeLatest(audioAction.HANDLE_PAUSE, handlePause);
}

export function* watchHandlePlay() {
  yield takeLatest(audioAction.HANDLE_PLAY, handlePlay);
}

//-------------------------------------
//  ROOT
//-------------------------------------

const root = [
  fork(watchFetchAudio),
  fork(watchSetPlayerState),
  fork(watchHandleDuration),
  fork(watchHandleProgress),
  fork(watchHandlePause),
  fork(watchHandlePlay),
];

export default root;
