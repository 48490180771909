const action = {
  TOGGLE_MENU: 'TOGGLE_MENU',
  TOGGLE_LIST: 'TOGGLE_LIST',
  TOGGLE_DETAIL: 'TOGGLE_DETAIL',
  TOGGLE_MINI_STATE: 'TOGGLE_MINI_STATE',
  SET_DETAIL_STATE: 'SET_DETAIL_STATE',
  CHANGE_MAP: 'CHANGE_MAP',
  FETCH_SELECTED_MAP: 'FETCH_SELECTED_MAP',
  SET_SELECTED_MAP: 'SET_SELECTED_MAP',
  SET_AUDIO_LIST: 'SET_AUDIO_LIST',
  SET_LAYOUT_DATA: 'SET_LAYOUT_DATA',
  FETCH_SELECTED_DETAIL: 'FETCH_SELECTED_DETAIL',
  SET_DETAIL_INFO: 'SET_DETAIL_INFO',
  SET_MAP_CENTER: 'SET_MAP_CENTER',

  toggleDetail(bool) {
    return {
      type: this.TOGGLE_DETAIL,
      payload: bool,
    };
  },

  setLayoutData(data) {
    return {
      type: this.SET_LAYOUT_DATA,
      payload: data,
    };
  },

  toogleList(boolean) {
    return {
      type: this.TOGGLE_LIST,
      payload: boolean,
    };
  },

  changeMap(selectedId, navigate) {
    return {
      type: this.CHANGE_MAP,
      payload: { selectedId, navigate },
    };
  },

  setSelectedMap(value) {
    return {
      type: this.SET_SELECTED_MAP,
      payload: value,
    };
  },

  setAudioList(data) {
    return {
      type: this.SET_AUDIO_LIST,
      payload: data,
    };
  },

  fetchSelectedDetail(audioObj: Object, playing: Boolean) {
    return {
      type: this.FETCH_SELECTED_DETAIL,
      payload: { audioObj, playing },
    };
  },

  setMapCenter(data) {
    return {
      type: this.SET_MAP_CENTER,
      payload: data,
    };
  },
};

export default action;
