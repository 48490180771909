import React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// import { useNavigate } from 'react-router-dom';

// import env from 'config/env';
// import { languages } from 'config/i18n';
// import { globalAction } from 'core/global';
import { mapAction } from 'core/map';

import { LinkBtn } from 'components/global/Button';
import CustomLink from 'components/global/CustomLink';
import Burger from 'components/icon/Burger';
import { Playlist } from 'components/icon/Svg';

function Menu() {
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const { t } = useTranslation(['common']);
  // const { language } = useSelector((state) => state.global);
  // const {
  //   layoutData: { params: { lang, pid, name } = {} },
  // } = useSelector((state) => state.map);
  const {
    layoutData: {
      footerNav = [
        {
          name: t('footer.link.contact'),
          url: t('footer.link.contactUrl'),
          target: '_blank',
        },
        {
          name: t('footer.link.company'),
          url: t('footer.link.companyUrl'),
          target: '_blank',
        },
      ],
    } = {},
    showList,
    showMenu,
    // showDetail,
  } = useSelector((state) => state.map);
  // const oppositeLanguage = languages.filter((l) => l !== language);

  const handleMenu = () => dispatch({ type: mapAction.TOGGLE_MENU });

  const handleList = () => dispatch(mapAction.toogleList(!showList));

  // const handleLanguage = () => {
  //   if (lang) {
  //     navigate(`/${oppositeLanguage[0]}/soundmap/${pid}/${name}`);
  //   }

  //   dispatch(globalAction.changeLanguage(oppositeLanguage[0], true));
  // };

  return (
    <Wrap>
      <BtnWrap>
        <MenuBtn onClick={handleMenu}>
          <Burger active={showMenu} />
        </MenuBtn>

        {/* <MenuBtn
          className={showMenu ? 'active' : ''}
          onClick={handleLanguage}
          padding="4px"
        >
          {oppositeLanguage[0]}
        </MenuBtn> */}

        <MenuBtn onClick={handleList} padding="0px">
          <PlaylistWrap className={showList ? ' active' : ''}>
            <Playlist />
          </PlaylistWrap>
        </MenuBtn>
      </BtnWrap>

      <Nav className={`${showMenu ? ' j-menu' : ''}`}>
        {/* <LinkBtn>
          <CustomLink>{t('footer.link.about')}</CustomLink>
        </LinkBtn> */}

        {footerNav.length
          ? footerNav.map((link, index) => (
              <CustomLink
                key={`footNav${link.url}${link.name}`}
                href={link.url}
                target="_blank"
                rel="noreferrer noopenner"
              >
                {link.name}
              </CustomLink>
            ))
          : null}
      </Nav>
    </Wrap>
  );
}

Menu.propTypes = {};

Menu.defaultProps = {};

export default Menu;

const Wrap = styled.div`
  width: 100%;
  @media (min-width: ${({ theme }) => theme.breakpoint.lg}) {
    display: flex;
    justify-content: flex-start;
  }
`;

const BtnWrap = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  z-index: 1;
`;

const MenuBtn = styled(LinkBtn)`
  width: 24px;
  height: 28px;
  padding: ${({ padding }) => padding || '8px 4px'};
  font-size: 12px;
  font-weight: 100;
  background-color: #262322;
  border-radius: 2px;
  /* opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s; */
  text-transform: uppercase;
  &:not(:last-child) {
    margin-right: 16px;
  }
  /* &.active {
    opacity: 1;
    pointer-events: auto;
  } */
  @media (min-width: ${({ theme }) => theme.breakpoint.lg}) {
    &:first-child {
      display: none;
    }
    &:not(:first-child) {
      opacity: 1;
      pointer-events: auto;
    }
  }
`;

const Nav = styled.nav`
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 400px;
  font-size: 12px;
  line-height: 18px;
  background-color: ${({ theme }) => theme.background};
  transform: translate(0, 100%);
  transition: transform ease-in-out 0.3s;
  & > a,
  & > button {
    margin: 6px 0;
    font-weight: 100;
  }
  &.j-menu {
    transform: translate(0, 0);
  }
  @media (min-width: ${({ theme }) => theme.breakpoint.lg}) {
    position: static;
    flex-direction: row;
    width: unset;
    height: 40px;
    transform: translate(0, 0);
    & > a,
    & > button {
      margin-left: 32px;
    }
  }
`;

const PlaylistWrap = styled.i`
  & path {
    fill: ${({ theme }) => theme.font};
  }
  &.active {
    path {
      fill: ${({ theme }) => theme.primary};
    }
  }
`;
