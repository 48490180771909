/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled from 'styled-components/macro';

function Close({ ...props }) {
  return (
    <Wrap {...props}>
      <Icon />
    </Wrap>
  );
}

export default Close;

const Wrap = styled.button.attrs({ type: 'button' })`
  box-sizing: border-box;
  display: block;
  width: 33px;
  height: 33px;
  padding: 0;
  border: 0;
  outline: none;
  cursor: pointer;
  background: transparent;
`;

const Icon = styled.i`
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  background: transparent;
  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50%;
    height: 1px;
    border-radius: 500px;
    background-color: ${({ theme }) => theme.middleGary};
  }
  &::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`;
