import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import Color from 'color';

import globalAction from 'core/global/action';

import Close from 'components/icon/Close';

function Alert({ theme, title, children, autoClose }) {
  const dispatch = useDispatch();
  const handleClose = () => dispatch(globalAction.setAlert(null));
  const handleStop = (e) => e.stopPropagation();

  useEffect(() => {
    if (autoClose) {
      setTimeout(() => handleClose(), autoClose);
    }
  });

  return (
    <Wrap onClick={handleClose}>
      <Info onClick={handleStop}>
        <Icon>
          {theme === 'success' ? (
            <svg width="32px" height="32px" viewBox="0 0 32 32">
              <defs>
                <path
                  d="M15.9955146,2.48689958e-14 C24.8257071,0.00943933054 31.9816569,7.16538912 31.9910293,15.9955146 C31.9910293,24.82959 24.82959,31.9910293 15.9955146,31.9910293 C7.16143933,31.9910293 2.48689958e-14,24.82959 2.48689958e-14,15.9955146 C2.48689958e-14,7.16143933 7.16143933,2.48689958e-14 15.9955146,2.48689958e-14 Z M15.9955146,2.28505439 C8.42343096,2.28505439 2.28505439,8.42343096 2.28505439,15.9955146 C2.28505439,23.5675983 8.42343096,29.7059749 15.9955146,29.7059749 C23.564251,29.6978075 29.6978075,23.564251 29.7059749,15.9955146 C29.7059749,8.42343096 23.5675983,2.28505439 15.9955146,2.28505439 Z M23.2841851,9.38998617 C23.7272038,9.04961284 24.3618027,9.07797729 24.772954,9.4750795 C25.2268452,9.91343933 25.239431,10.6367197 24.8010711,11.0905439 L24.8010711,11.0905439 L13.3756653,22.5159498 C12.9295397,22.9620084 12.2062594,22.9620084 11.7601339,22.5159498 L11.7601339,22.5159498 L7.18995816,17.9457741 L7.18995816,17.9457741 L7.16190795,17.9177238 C6.72354812,17.4638996 6.73606695,16.7405523 7.18995816,16.3021925 C7.64378243,15.8638326 8.36712971,15.8763515 8.80548954,16.3302427 L8.80548954,16.3302427 L12.5678996,20.0926527 L23.1855397,9.4750795 Z"
                  id="Alert-svg-path-1"
                />
              </defs>
              <g
                id="front-end"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
              >
                <g
                  id="result-modify-feedback-success"
                  transform="translate(-498.000000, -489.000000)"
                >
                  <g
                    id="glyph/success"
                    transform="translate(498.000000, 489.000000)"
                  >
                    <mask id="Alert-svg-mask-2" fill="white">
                      <use xlinkHref="#Alert-svg-path-1" />
                    </mask>
                    <use
                      id="Combined-Shape"
                      fill="#000000"
                      fillRule="nonzero"
                      xlinkHref="#Alert-svg-path-1"
                    />
                    <g
                      id="glyph-color"
                      mask="url(#Alert-svg-mask-2)"
                      fill="#28E1A5"
                    >
                      <g
                        transform="translate(-4.000000, -4.000000)"
                        id="Rectangle-Copy-2"
                      >
                        <rect x="0" y="0" width="40" height="40" />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          ) : null}
          {theme === 'info' ? (
            <svg width="32px" height="32px" viewBox="0 0 32 32">
              <defs>
                <path
                  d="M16,0 C24.8227539,0 32,7.17724612 32,16 C32,24.8227539 24.8227539,32 16,32 C7.17724612,32 0,24.8227539 0,16 C0,7.17724612 7.17724612,0 16,0 Z M16,2 C8.28002931,2 2,8.28002931 2,16 C2,23.7199707 8.28002931,30 16,30 C23.7199707,30 30,23.7199707 30,16 C30,8.28002931 23.7199707,2 16,2 Z M13,25.3332519 C12.4479981,25.3332519 12,24.8852539 12,24.3332519 C12,23.78125 12.4479981,23.3332519 13,23.3332519 L15,23.333 L15,14 L13.6667481,14 C13.1147461,14 12.6667481,13.5520019 12.6667481,13 C12.6667481,12.4479981 13.1147461,12 13.6667481,12 L16,12 C16.5520019,12 17,12.4479981 17,13 L17,23.333 L19,23.3332519 C19.5520019,23.3332519 20,23.78125 20,24.3332519 C20,24.8852539 19.5520019,25.3332519 19,25.3332519 L13,25.3332519 Z M16,6.66674806 C16.7363281,6.66674806 17.3332519,7.26367188 17.3332519,8 C17.3332519,8.73632812 16.7363281,9.33325194 16,9.33325194 C15.2636719,9.33325194 14.6667481,8.73632812 14.6667481,8 C14.6667481,7.26367188 15.2636719,6.66674806 16,6.66674806 Z"
                  id="Alert-svg-path-1"
                />
              </defs>
              <g
                id="front-end"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
              >
                <g
                  id="result-modify-feedback-info"
                  transform="translate(-498.000000, -489.000000)"
                >
                  <g
                    id="glyph/info"
                    transform="translate(498.000000, 489.000000)"
                  >
                    <mask id="Alert-svg-mask-2" fill="white">
                      <use xlinkHref="#Alert-svg-path-1" />
                    </mask>
                    <use
                      id="Combined-Shape"
                      fill="#000000"
                      fillRule="nonzero"
                      xlinkHref="#Alert-svg-path-1"
                    />
                    <g
                      id="glyph-color"
                      mask="url(#Alert-svg-mask-2)"
                      fill="#0069D2"
                    >
                      <g
                        transform="translate(-4.000000, -4.000000)"
                        id="Rectangle-Copy-2"
                      >
                        <rect x="0" y="0" width="40" height="40" />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          ) : null}
          {theme === 'error' ? (
            <svg width="32px" height="29px" viewBox="0 0 32 29">
              <defs>
                <path
                  d="M15.9999688,-7.63833441e-14 C17.3603398,-7.63833441e-14 18.5785237,0.703372939 19.2586467,1.88136949 L19.2586467,1.88136949 L31.4898609,23.0664324 C32.1700464,24.2444915 32.1700464,25.6511124 31.4898609,26.8292339 C30.8096129,28.0071679 29.5914289,28.7105409 28.2311204,28.7105409 L28.2311204,28.7105409 L3.76881708,28.7105409 C2.40844607,28.7105409 1.19032464,28.0072304 0.51013913,26.8291714 C-0.170046377,25.6511124 -0.170046377,24.2444915 0.51013913,23.0663699 L0.51013913,23.0663699 L12.7412908,1.88136949 C13.4214138,0.703372939 14.6396602,-7.63833441e-14 15.9999688,-7.63833441e-14 Z M16.0000312,1.87480701 C15.3174707,1.87480701 14.7062225,2.22768097 14.3649735,2.81880424 L14.3649735,2.81880424 L2.13369687,24.0038672 C1.79244787,24.5949904 1.79244787,25.3007384 2.13369687,25.8918616 C2.47494587,26.4829849 3.08619408,26.8358589 3.76881708,26.8358589 L3.76881708,26.8358589 L28.2311204,26.8358589 C28.9136809,26.8358589 29.5249291,26.4829849 29.8662406,25.8918616 C30.2074896,25.3007384 30.2074896,24.5949904 29.8663031,24.0038672 L29.8663031,24.0038672 L17.635089,2.81880424 C17.29384,2.22768097 16.6825293,1.87480701 16.0000312,1.87480701 Z M15.9999688,20.6046271 C16.6891542,20.6046271 17.2498401,21.165313 17.2498401,21.8544985 C17.2498401,22.543684 16.6890917,23.1043698 15.9999688,23.1043698 C15.3107833,23.1043698 14.7500974,22.543684 14.7500974,21.8544985 C14.7500974,21.165313 15.3107833,20.6046271 15.9999688,20.6046271 Z M16.9372785,9.35591009 L16.9372785,18.7297576 L15.062534,18.7297576 L15.062534,9.35591009 L16.9372785,9.35591009 Z"
                  id="Alert-svg-path-1"
                />
              </defs>
              <g
                id="front-end"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
              >
                <g
                  id="result-modify-feedback-alert"
                  transform="translate(-498.000000, -491.000000)"
                >
                  <g
                    id="glyph/alert"
                    transform="translate(498.000000, 491.000000)"
                  >
                    <mask id="Alert-svg-mask-2" fill="white">
                      <use xlinkHref="#Alert-svg-path-1" />
                    </mask>
                    <use
                      id="Combined-Shape"
                      fill="#000000"
                      fillRule="nonzero"
                      xlinkHref="#Alert-svg-path-1"
                    />
                    <g
                      id="glyph-color"
                      mask="url(#Alert-svg-mask-2)"
                      fill="#FF1923"
                    >
                      <g
                        transform="translate(-4.000000, -6.000000)"
                        id="Rectangle-Copy-2"
                      >
                        <rect x="0" y="0" width="40" height="40" />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          ) : null}
        </Icon>

        <Content>
          {title ? <Title>{title}</Title> : null}

          {children}
        </Content>

        <AlertClose onClick={handleClose} />
      </Info>
    </Wrap>
  );
}

Alert.propTypes = {
  children: PropTypes.node.isRequired,
  theme: PropTypes.oneOf(['success', 'info', 'error']),
  title: PropTypes.string,
  autoClose: PropTypes.number,
};

Alert.defaultProps = {
  theme: 'info',
  title: null,
  autoClose: undefined,
};

export default Alert;

export const AlertClose = styled(Close)`
  & > i {
    &::before,
    &::after {
      background-color: ${({ theme }) => theme.font};
    }
  }
`;

export const Title = styled.h3`
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.21px;
  line-height: 24px;
  margin: 0 0 4px;
`;

export const Content = styled.div`
  width: calc(100% - 73px);
  padding: 0 28px;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.21px;
  line-height: 24px;
  & p {
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0.21px;
    line-height: 24px;
    margin: 0;
    &:not(:last-child) {
      margin-bottom: 4px;
    }
  }
`;

export const Icon = styled.i`
  line-height: 0;
`;

export const Info = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 1.5;
  width: 80vw;
  max-width: 500px;
  padding: 24px 28px;
  background-color: ${({ theme }) => theme.background};
  box-shadow: 10px 9px 15px 0 rgba(128, 152, 213, 0.1);
  z-index: 1;
`;

export const Wrap = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: ${({ theme }) =>
    Color(theme.middleGary).alpha(0.3).string()};
  z-index: ${({ theme }) => theme.zIndex.alert};
`;
