/* eslint-disable no-bitwise */
export const errorMessageHelper = (status, code, message) => {
  if ([500, 400, 401, 403, 404].includes(status)) {
    return message;
  }

  return `${code} - ${message}`;
};

export const arrShuffle = (arr) => {
  const array = [...arr];

  for (let i = array.length - 1; i > 0; i -= 1) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }

  return array;
};

export const audioTimeFormat = (time) => {
  // Hours, minutes and seconds
  const hrs = ~~(time / 3600);
  const mins = ~~((time % 3600) / 60);
  const secs = ~~time % 60;

  // Output like "1:01" or "4:03:59" or "123:03:59"
  let ret = '';
  if (hrs > 0) {
    ret += `${hrs}:${mins < 10 ? '0' : ''}`;
  }
  ret += `${String(mins).padStart(2, '0')}:${secs < 10 ? '0' : ''}`;
  ret += `${secs}`;
  return ret;
};

export const detectIsMobile = (checkWidth = false) => {
  if (checkWidth) {
    return document.getElementById('root').offsetWidth < 992;
  }

  const userAgent =
    typeof window.navigator === 'undefined' ? '' : navigator.userAgent;

  return Boolean(
    userAgent.match(
      /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
    )
  );
};

export const isArraysEqualEps = (arrayA, arrayB, eps) => {
  if (arrayA && arrayB) {
    for (let i = 0; i !== arrayA.length; i += 1) {
      if (Math.abs(arrayA[i] - arrayB[i]) > eps) {
        return false;
      }
    }
    return true;
  }
  return false;
};

export const detectDeviceOs = () => {
  const { userAgent } = window.navigator;
  const platform =
    window.navigator?.userAgentData?.platform || window.navigator.platform;
  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K', 'macOS'];
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
  const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
  let os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'Mac OS';
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows';
  } else if (/Android/.test(userAgent)) {
    os = 'Android';
  } else if (/Linux/.test(platform)) {
    os = 'Linux';
  } else {
    os = 'undefined';
  }

  return os;
};

export const getFormatPosition = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const posParams = searchParams.get('pos');
  const currentPosArr = posParams ? posParams.split(',') : [];

  return currentPosArr[0] && currentPosArr[1]
    ? {
        latitude: currentPosArr[0],
        longitude: currentPosArr[1],
        zoom: currentPosArr[2],
      }
    : undefined;
};
