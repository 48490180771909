import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
// import { useSelector } from 'react-redux';

// import env from 'config/env';

import Header from 'container/layout/Header';
import Footer from 'container/layout/Footer';
import Information from 'container/layout/Information';
// import DevLogin from 'container/other/DevLogin';

function GlobalLayout({ children }) {
  // const { devLogin } = useSelector((state) => state.global);

  return (
    // <>
    //   {env.runDev !== 'dev' || (env.runDev === 'dev' && devLogin) ? (
    <Wrap>
      <Header />

      {children}

      <Information />

      <Footer />
    </Wrap>
    //   ) : (
    //     <DevLogin />
    //   )}
    // </>
  );
}

GlobalLayout.propTypes = {
  children: PropTypes.node,
};

GlobalLayout.defaultProps = {
  children: undefined,
};

export default GlobalLayout;

const Wrap = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  background-color: ${({ theme }) => theme.mapBackground};
`;
