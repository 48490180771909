import React, { useRef } from 'react';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';

import env from 'config/env';
import mapStyle from 'config/map';
import { detectIsMobile } from 'core/helper/util';

import GoogleMap from 'components/global/GoogleMap';
import Marker from 'container/Map/Marker';

const MemoGoogleMap = React.memo(GoogleMap);

function CustomMap() {
  const {
    showDetail,
    miniDetail,
    audioList,
    layoutData: { level } = {},
    mapCenter: { lat, lng },
  } = useSelector((state) => state.map);
  const { onload } = useSelector((state) => state.global);
  const mapRef = useRef(null);
  const currentOptions = {
    styles: [...mapStyle],
    minZoom: 2,
    maxZoom: 15,
    disableDefaultUI: true,
    // 限制在特定邊界
    // restriction: {
    //   latLngBounds: {
    //     north: -10,
    //     south: -40,
    //     east: 160,
    //     west: 100,
    //   },
    // },
  };
  const currentLevel = level
    ? Number(
        level.includes(',')
          ? level.split(',')[detectIsMobile(true) ? 0 : 1]
          : level
      )
    : undefined;

  // const handleChange = (map) => {
  //   console.log('Map moved', map, mapReady);
  // };

  const onGoogleApiLoaded = ({ map, maps }) => {
    mapRef.current = map;
  };

  return (
    <Wrap
      className={`${onload ? ' j-onload' : ''}${miniDetail ? ' j-mini' : ''}${
        showDetail ? ' j-detail' : ''
      }`}
    >
      <MemoGoogleMap
        apiKey={env.mapApiKey}
        currentCenter={{ lat: Number(lat), lng: Number(lng) }}
        currentZoom={currentLevel && !isNaN(currentLevel) ? currentLevel : 8}
        options={currentOptions}
        onGoogleApiLoaded={onGoogleApiLoaded}
        // onChange={handleChange}
      >
        {audioList.map((marker, index) => (
          <Marker
            // eslint-disable-next-line react/no-array-index-key
            key={`marker${marker.latitude},${marker.longitude}_${index}`}
            lat={Number(marker.latitude)}
            lng={Number(marker.longitude)}
            data={marker}
          />
        ))}
      </MemoGoogleMap>
    </Wrap>
  );
}

CustomMap.propTypes = {};

CustomMap.defaultProps = {};

export default React.memo(CustomMap);

const Wrap = styled.div`
  position: relative;
  height: calc(100% + 100px);
  width: calc(100% + 100px);
  margin: -50px;
  transition: transform 1s ease-in-out 0.3s, opacity 0.9s linear 0.4s;
  opacity: 0;
  transform: scale(0.3);
  &.j-onload {
    opacity: 1;
    transform: scale(1);
  }
  &.j-detail {
    @media (orientation: portrait) {
      bottom: calc(50vh - (((100vh - 132px - 452px) / 2) + 132px));
    }
    &::after {
      position: absolute;
      display: block;
      content: '';
      width: 100%;
      height: 25px;
      left: 0;
      bottom: 0;
      background-color: ${({ theme }) => theme.mapBackground};
    }
  }
  &.j-mini {
    bottom: 0;
  }
  @media (min-width: ${({ theme }) => theme.breakpoint.lg}) {
    &.j-detail {
      top: -100px;
      &::after {
        display: none;
      }
    }
    &.j-mini {
      top: 0;
    }
  }
`;
