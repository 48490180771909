import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { isArraysEqualEps } from 'core/helper/util';
import MapMarkers from './markers';

const EPS = 0.00001;

function MapComponent({
  children,
  style,
  currentCenter,
  currentZoom,
  onGoogleApiLoaded,
  onChange,
  options,
}) {
  const mapRef = useRef(null);
  const prevBoundsRef = useRef(null);
  const [map, setMap] = useState(null);
  const [maps, setMaps] = useState(null);
  const [googleApiCalled, setGoogleApiCalled] = useState(false);

  const onIdle = useCallback(() => {
    const zoom = map.getZoom();
    const bounds = map.getBounds();
    const centerLatLng = map.getCenter();

    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();
    const boundsArray = [sw.lng(), sw.lat(), ne.lng(), ne.lat()];

    if (!isArraysEqualEps(boundsArray, prevBoundsRef.current, EPS)) {
      if (onChange) {
        onChange({
          zoom,
          center: [centerLatLng.lng(), centerLatLng.lat()],
          bounds,
        });
      }
      prevBoundsRef.current = boundsArray;
    }
  }, [map, onChange]);

  useEffect(() => {
    if (mapRef.current && !map) {
      setMap(
        new window.google.maps.Map(mapRef.current, {
          center: currentCenter,
          zoom: currentZoom,
          ...options,
        })
      );
      setMaps(window.google.maps);
    }
  }, [currentCenter, currentZoom, map, mapRef, options]);

  useEffect(() => {
    if (map) {
      if (!googleApiCalled) {
        onGoogleApiLoaded({ map, maps, ref: mapRef.current });
        setGoogleApiCalled(true);
      }

      window.google.maps.event.clearListeners(map, 'idle');
      // Idle event is fired when the map becomes idle after panning or zooming.
      window.google.maps.event.addListener(map, 'idle', onIdle);
    }
  }, [googleApiCalled, map, maps, onChange, onGoogleApiLoaded, onIdle]);

  useEffect(
    () =>
      // clear listeners on unmount
      () => {
        if (map) {
          window.google.maps.event.clearListeners(map, 'idle');
        }
      },
    [map]
  );

  useEffect(() => {
    if (map) {
      map.setCenter(currentCenter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCenter]);

  useEffect(() => {
    if (map) {
      map.setZoom(currentZoom);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentZoom]);

  return (
    <>
      <div ref={mapRef} style={style} className="google-map" />
      {children && map && maps && (
        <MapMarkers map={map} maps={maps}>
          {children}
        </MapMarkers>
      )}
    </>
  );
}

MapComponent.defaultProps = {
  style: {
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    margin: 0,
    padding: 0,
    position: 'absolute',
  },
  onGoogleApiLoaded: () => {},
  onChange: () => {},
  options: {},
};

MapComponent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  style: PropTypes.shape(),
  currentCenter: PropTypes.shape().isRequired,
  currentZoom: PropTypes.number.isRequired,
  onGoogleApiLoaded: PropTypes.func,
  onChange: PropTypes.func,
  options: PropTypes.shape(),
};

export default MapComponent;
