import React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { mapAction } from 'core/map';
import { globalAction } from 'core/global';

import Button, { LinkBtn } from 'components/global/Button';
import { VerticalArrow } from 'components/icon/Arrow';

function Detail() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation(['common']);
  const {
    miniDetail,
    selectedDetail: {
      title,
      soundmap: { title: subtitle = '' } = {},
      content = '',
      uri = '',
      // eslint-disable-next-line camelcase
      soundmap_id = '',
    } = {},
  } = useSelector((state) => state.map);

  const handleClick = () => dispatch({ type: mapAction.TOGGLE_MINI_STATE });

  const handleChangeMap = () =>
    dispatch(mapAction.changeMap(soundmap_id, navigate));

  const handleHrefTo = () => {
    dispatch(globalAction.sendGtagEvent('curiosity_related', 'related', title));
    window.open(uri, '_blank');
  };

  return (
    <Wrap className={`${miniDetail ? ' j-mini' : ''}`}>
      <Title>
        <Info>{title || t('info.default')}</Info>

        {title ? (
          <MiniBtn onClick={handleClick}>
            <VerticalArrow className={miniDetail ? 'active' : ''} />
            {miniDetail ? t('info.expand') : t('info.putAway')}
          </MiniBtn>
        ) : null}
      </Title>

      <MapLink
        className={miniDetail ? 'j-hide' : ''}
        underline
        onClick={handleChangeMap}
      >
        {subtitle}
      </MapLink>

      <Summary className={miniDetail ? 'j-hide' : ''}>{content}</Summary>

      <SeeMore
        className={miniDetail ? 'j-hide' : ''}
        underline
        onClick={handleHrefTo}
      >
        {t('info.readMore')}
      </SeeMore>
    </Wrap>
  );
}

Detail.propTypes = {};

Detail.defaultProps = {};

export default Detail;

const Wrap = styled.div`
  margin-bottom: 24px;
  &::-webkit-scrollbar {
    width: 1px;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(0,0,0,1);
  }
  &::-webkit-scrollbar-track {
    background:     background-color: rgba(0,0,0,0.3);;
  }
  &.j-mini {
    margin-bottom: 0;
  }
  @media (min-width: ${({ theme }) => theme.breakpoint.lg}) {
    max-height: 210px;
    margin-bottom: 0;
    padding: 0 40px 0 0;
    overflow-y: auto;
  }
`;

const MiniBtn = styled(Button)`
  > i {
    width: 8px;
    height: 8px;
    margin: 4px 8px 4px 4px;
    &::before,
    &::after {
      background-color: ${({ theme }) => theme.font};
    }
  }
`;

const Info = styled.span`
  display: block;
  margin-right: 8px;
  width: 100%;
  @media (min-width: ${({ theme }) => theme.breakpoint.lg}) {
    margin-right: 24px;
  }
`;

const Title = styled.h3`
  display: inline-flex;
  font-size: 14px;
  line-height: 27px;
  margin: 0 0 8px;
  @media (min-width: ${({ theme }) => theme.breakpoint.lg}) {
    font-size: 18px;
  }
`;

const MapLink = styled(LinkBtn)`
  display: block;
  font-weight: 100;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.33px;
  margin: 0 0 8px;
  color: ${({ theme }) => theme.primary};
`;

const Summary = styled.p`
  font-weight: 100;
  font-size: 12px;
  line-height: 18px;
  margin: 0 0 12px;
`;

const SeeMore = styled(LinkBtn)`
  font-weight: 100;
  font-size: 12px;
  line-height: 18px;
  color: ${({ theme }) => theme.font};
`;
