import React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';
import Color from 'color';

import env from 'config/env';
import Image from 'components/global/Image';
import CustomLink from 'components/global/CustomLink';
import Menu from 'container/layout/Footer/Menu';
import Playlist from 'container/layout/Footer/Playlist';

function Footer() {
  const { onload } = useSelector((state) => state.global);

  return (
    <>
      <Wrap className={`${onload ? ' j-onload' : ''}`}>
        <Menu />

        <LogoLink
          href="https://sunrisemedium.com/"
          target="_blank"
          rel="noreferrer noopenner"
        >
          <Image
            src={`${env.routerBaseName}/asset/image/icon/sunriseLogo.svg`}
            alt="sunrise logo"
          />
        </LogoLink>
      </Wrap>
      <Playlist />
    </>
  );
}

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;

const Wrap = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40px;
  padding: 0 20px;
  background-color: ${({ theme }) =>
    Color(theme.background).alpha(0.7).string()};
  z-index: ${({ theme }) => theme.zIndex.footer};
  opacity: 0;
  transform: translateY(100%);
  transition: transform 0.5s ease-in-out 1.3s, opacity 0.5s linear 1.3s;
  &.j-onload {
    opacity: 1;
    transform: translateY(0);
  }
  @media (min-width: ${({ theme }) => theme.breakpoint.lg}) {
    & {
      background-color: ${({ theme }) =>
        Color(theme.background).alpha(0.9).string()};
    }
  }
`;

const LogoLink = styled(CustomLink)`
  display: block;
  width: 120px;
  min-width: 120px;
  height: 12px;
  z-index: 1;
  @media (min-width: ${({ theme }) => theme.breakpoint.lg}) {
    & {
      width: 160px;
      min-width: 160px;
      height: 16px;
    }
  }
`;
