import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';

// const themeIconColor = ({ theme }) => theme.color.primary;

function MenuToggler({ active }) {
  return (
    <IconContent>
      <Line active={active} />
      <Line active={active} />
      <Line active={active} />
    </IconContent>
  );
}

MenuToggler.propTypes = {
  active: PropTypes.bool,
};
MenuToggler.defaultProps = {
  active: undefined,
};

const IconContent = styled.i`
  position: relative;
  display: block;
  height: 100%;
  width: 100%;
`;

const Line = styled.hr`
  display: block;
  position: absolute;
  left: 0;
  width: 100%;
  height: 1.4px;
  margin: 0;
  transition: transform 0.3s, top 0.3s 0.3s, width 0.3s 0.3s;
  border: 0;
  border-radius: 500px;
  background-color: #ffffff;
  ${({ active }) =>
    active
      ? css`
          transition: transform 0.3s 0.3s, top 0.3s, width 0.3s;
        `
      : null}
  &:first-child {
    top: ${({ active }) => (active ? '50%' : 0)};
    ${({ active }) =>
      active
        ? css`
            transform: translateY(-50%) rotate(45deg);
          `
        : null}
  }
  &:nth-child(2) {
    top: 50%;
    transform: translateY(-50%);
    transition: opacity 0.1s 0.3s;
    opacity: ${({ active }) => (active ? 0 : 1)};
  }
  &:last-child {
    top: ${({ active }) => (active ? '50%' : '100%')};
    /* width: ${({ active }) => (active ? '100%' : '75%')}; */

    ${({ active }) =>
      active
        ? css`
            transform: translateY(-50%) rotate(-45deg);
          `
        : css`
            transform: translateY(-100%);
          `}
  }
`;

export default MenuToggler;
