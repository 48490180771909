import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import ReactPlayer from 'react-player';
import { useDispatch, useSelector } from 'react-redux';

import env from 'config/env';
import { globalAction } from 'core/global';
import { audioAction } from 'core/audio';

function Audio({ src, audioObj, options, onReady }) {
  const [init, setInit] = useState(false);
  const isPlayed = useRef(false);
  const dispatch = useDispatch();
  const { fetchedAudios, playAfterFetch, playingAudio } = useSelector(
    (state) => state.audio
  );
  const currentAudio = structuredClone(fetchedAudios).find(
    (audio) => audio.id === audioObj.id
  );
  const {
    loadedSeconds,
    podcast,
    loaded,
    played,
    time,
    playerState,
    duration = '00:00',
  } = currentAudio;

  useEffect(() => {
    if (init && playAfterFetch && playAfterFetch === podcast) {
      dispatch(audioAction.setPlayerState(currentAudio));
      dispatch(audioAction.playAfterFetch(''));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [init, playAfterFetch]);

  const handleReady = () => {
    setInit(true);
    dispatch({ type: globalAction.FETCH_FINISH });
  };

  const handleDuration = (value) =>
    dispatch({
      type: audioAction.HANDLE_DURATION,
      payload: { currentAudio, duration: value },
    });

  const handleProgress = (state) => {
    if (
      state.playedSeconds !== 0 &&
      currentAudio.playerState === 'play' &&
      state.playedSeconds - currentAudio.time > 1
    ) {
      dispatch({
        type: audioAction.HANDLE_PROGRESS,
        payload: { currentAudio, ...state },
      });
    }
  };

  const handlePause = () =>
    dispatch({ type: audioAction.HANDLE_PAUSE, payload: currentAudio });

  const handlePlay = () => {
    dispatch({
      type: audioAction.HANDLE_PLAY,
      payload: { currentAudio, isPlayed: isPlayed.current },
    });
    isPlayed.current = true;
  };

  const handleError = (error, data, hlsInstance, hlsGlobal) => {
    // eslint-disable-next-line no-console
    console.log(error, data, hlsInstance, hlsGlobal);
    dispatch({ type: globalAction.FETCH_FINISH });
    // const { origin } =
    //   typeof window !== 'undefined' ? window.location : { origin: '' };

    // dispatch(
    //   globalAction.setAlert({
    //     title: err.type,
    //     theme: 'error',
    //     message: `Cross-Origin Read Blocking (CORB) 已封鎖 MIME 類型為text/html的${origin}跨來源回應。`,
    //   })
    // );
  };

  return (
    <>
      <Wrap>
        <Content>
          <ReactPlayer
            url={src}
            playing={playingAudio === podcast}
            onReady={handleReady}
            onDuration={handleDuration}
            onProgress={handleProgress}
            onPause={handlePause}
            onPlay={handlePlay}
            onError={handleError}
            config={{
              file: {
                forceAudio: true,
                forceHLS: env.currentOs !== 'iOS',
                hlsOptions: {
                  defaultAudioCodec: 'mp4a.40.2',
                },
              },
            }}
          />
        </Content>
      </Wrap>

      {env.runDev === 'local' ? (
        <Deltail>
          <p>playerState: {playerState}</p>
          <p>time: {time}</p>
          <p>played: {played}</p>
          <p>duration: {duration}</p>
          <p>loadedSeconds: {loadedSeconds}</p>
          <p>loaded: {loaded}</p>
        </Deltail>
      ) : null}
    </>
  );
}
Audio.propTypes = {
  src: PropTypes.string.isRequired,
  audioObj: PropTypes.shape(),
  options: PropTypes.shape(),
  onReady: PropTypes.func,
};

Audio.defaultProps = {
  audioObj: {},
  options: {},
  onReady: undefined,
};

export default Audio;

const Wrap = styled.div`
  position: fixed;
  pointer-events: none;
  opacity: 0;
  top: 0;
  left: 0;
`;

const Content = styled.div``;

const Deltail = styled.div`
  position: fixed;
  pointer-events: none;
  opacity: 0;
  top: 0;
  left: 0;
  padding: 20px;
  color: #000;
  background-color: #fff;
  z-index: 999999;
`;
