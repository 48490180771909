/* eslint-disable import/no-cycle */
import { call, takeLatest, fork, put, select, delay } from 'redux-saga/effects';
import i18next from 'i18next';
import Cookies from 'js-cookie';
import qs from 'qs';

import env from 'config/env';
import { baseFetch } from 'core/api';
import { mapAction } from 'core/map';
// import validate from 'core/model/validator';
import { languages } from 'config/i18n';
import globalAction from './action';

export function* fetchInitData({ payload }) {
  const { lang, pid, slug } = payload;
  const data = qs.stringify({ pid });
  const { language } = yield select((state) => state.global);
  const currentLang = lang || (yield call(Cookies.get, 'user_lang'));

  if (!currentLang || languages.every((l) => l !== currentLang)) {
    yield put(globalAction.changeLanguage(language));
  } else if ([language, i18next.language].some((l) => l !== currentLang)) {
    yield put(globalAction.changeLanguage(currentLang));
  }

  try {
    const apiUrl =
      env.runDev === 'local'
        ? `/soundmap/one?testing=${env.localApiKey}&lang=${currentLang}`
        : '/soundmap/one';

    const res = yield call(baseFetch, {
      method: 'post',
      url: apiUrl,
      data,
    });

    if (res.data.code === 1) {
      const initData = res.data.data;
      const { meta: { hitsLevel = '' } = {} } = initData;

      initData.params = { lang, pid, slug };
      initData.hitsLevel =
        hitsLevel === '' ? {} : JSON.parse(hitsLevel.replace(/&quot;/g, '"'));

      yield put({
        type: mapAction.FETCH_SELECTED_MAP,
        payload: { lang: currentLang, slug, initData },
      });
      yield delay(500);
      yield put({ type: globalAction.DOM_READY, payload: true });
    } else {
      yield put(
        globalAction.setAlert({
          theme: 'error',
          message: 'No Data Found',
          autoClose: 10000,
        })
      );
    }
  } catch (error) {
    yield put(
      globalAction.setAlert({
        theme: 'error',
        message: error.message,
        autoClose: 10000,
      })
    );
  }
}

// export function* validateForm({ payload: { schema, data } }) {
//   try {
//     const errors = yield call(validate, schema, data);

//     yield put({ type: globalAction.VALIDATE_RESULT, payload: errors });
//   } catch (err) {
//     yield put(
//       globalAction.setAlert({
//         theme: 'error',
//         message: err.message,
//       })
//     );
//   }
// }

export function* changeLanguage({ payload }) {
  const { lang, reload } = payload;

  yield call(i18next.changeLanguage, lang);
  yield call(Cookies.set, 'user_lang', lang);
  yield put({ type: globalAction.SET_LANGUAGE, lang });

  if (reload) {
    window.location.reload();
  }
}

export function* sendGtagEvent({ payload }) {
  const { gtag } = window;
  const { actionName, category, label = '', value = undefined } = payload;
  const currentEvent = {
    event_category: category,
    event_label: label,
  };

  if (value) {
    currentEvent.event_value = value;
  }

  // if (env.runDev === 'local') {
  //   // eslint-disable-next-line no-console
  //   console.info(`------ gtag: ${actionName} ------`, { ...currentEvent });
  // }

  yield call(gtag, 'event', actionName, { ...currentEvent });
}

//-------------------------------------
//  WATCHERS
//-------------------------------------

export function* watchFetchInitData() {
  yield takeLatest(globalAction.FETCH_INIT_DATA, fetchInitData);
}

// export function* watchValidateForm() {
//   yield takeLatest(globalAction.VALIDATE_FORM, validateForm);
// }

export function* watchChangeLanguage() {
  yield takeLatest(globalAction.CHANGE_LANGUAGE, changeLanguage);
}

export function* watchSendGtagEvent() {
  yield takeLatest(globalAction.SEND_GTAG_EVENT, sendGtagEvent);
}

//-------------------------------------
//  ROOT
//-------------------------------------

const root = [
  fork(watchFetchInitData),
  // fork(watchValidateForm),
  fork(watchChangeLanguage),
  fork(watchSendGtagEvent),
];

export default root;
