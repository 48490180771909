import audioAction from './action';

export const audioState = {
  playingAudio: '',
  fetchedAudios: [],
  playAfterFetch: '',
};

// eslint-disable-next-line default-param-last
const reducer = (state = audioState, action) => {
  const { type, payload } = action;

  switch (type) {
    case audioAction.SET_FETCHED_AUDIO:
      return {
        ...state,
        fetchedAudios: payload,
      };

    case audioAction.SET_PLAYING_AUDIO:
      return {
        ...state,
        playingAudio: payload,
      };

    case audioAction.PLAY_AFTER_FETCH:
      return {
        ...state,
        playAfterFetch: payload,
      };

    default:
      return state;
  }
};

export default reducer;
