/* eslint-disable react/prop-types */
import React from 'react';

export function Playlist({ color }) {
  return (
    <svg width="24px" height="28px" viewBox="0 0 24 28">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="28" rx="2" />
        <path
          d="M16.1908422,19.5761611 C16.9565825,19.5829406 17.5910181,18.9610144 17.6028714,18.1918624 C17.6147247,17.4301898 16.9894306,16.7905055 16.2209784,16.7779961 C15.4496394,16.7654867 14.8117922,17.3906059 14.8005075,18.1701241 C14.7895727,18.9267667 15.4249706,19.5693815 16.1908422,19.5761611 M17.6045773,15.7328503 L17.6045773,15.5346685 C17.6045773,12.9639468 17.6040524,10.3932251 17.6064143,7.82254716 C17.6065455,7.69876551 17.6124503,7.56811683 17.6511594,7.4525144 C17.7575766,7.13474985 18.0787091,6.95786895 18.4212737,7.0086063 C18.7335271,7.05488226 18.9773288,7.32746432 18.9961804,7.65253329 C18.9989797,7.70152108 18.9989359,7.75072756 18.9989359,7.79980283 C18.9990234,11.2328846 19.0020852,14.6659226 18.9974051,18.0990043 C18.9956992,19.3422444 18.426085,20.2569164 17.284757,20.7441699 C16.2289827,21.1949013 15.2120487,21.0247125 14.3503884,20.2619901 C13.4772686,19.4891203 13.1963761,18.4915189 13.5269562,17.3794524 C13.8515878,16.2873746 14.6217895,15.6321629 15.7490773,15.4237024 C16.3427481,15.313961 16.9124061,15.4165729 17.4513592,15.6879303 C17.4901558,15.7075254 17.5297834,15.7253272 17.569411,15.7431728 C17.5729539,15.7447474 17.5791211,15.7405922 17.6045773,15.7328503"
          fill={color}
        />
        <path
          d="M10.6181662,10.0005999 C12.2145996,10.0005999 13.8109891,10.0000312 15.4073787,10.0019558 C15.5046107,10.002087 15.6055605,10.0086478 15.6984186,10.0346726 C16.0096223,10.1219758 16.2124405,10.418352 16.1938514,10.7418463 C16.1758309,11.0563742 15.9444511,11.3209957 15.6317603,11.3807433 C15.5677262,11.3929465 15.5016802,11.3999448 15.4365527,11.3999448 C12.2109255,11.4008633 8.98529827,11.4013007 5.75967108,11.4001197 C5.31484446,11.3999885 5.00644009,11.117084 5,10.7137658 C4.99375575,10.309048 5.29616787,10.0026993 5.71413868,10.0015184 C6.68177872,9.99876281 7.64950625,10.0005999 8.61719003,10.0005999 L10.6181662,10.0005999 Z"
          fill={color}
        />
        <path
          d="M8.84568722,15.3918407 C7.80688366,15.3918407 6.76812385,15.3941152 5.72932029,15.3908348 C5.27290281,15.3893476 4.97136547,15.0685213 5.0021578,14.6308242 C5.02485839,14.3081609 5.29122949,14.034879 5.61393655,14.0056613 C5.67915154,13.9997565 5.74502262,14.0002376 5.81058753,14.0002376 C7.83347703,13.9999752 9.85636654,13.9996253 11.879256,14.0010687 C11.9823054,14.0011562 12.0895099,14.001681 12.1876604,14.0281869 C12.5319746,14.1213075 12.7350552,14.4487383 12.6843616,14.8097608 C12.6381294,15.13916 12.3526443,15.3882104 11.9948585,15.3896975 C10.9451639,15.3941152 9.89542555,15.3912721 8.84568722,15.3912721 L8.84568722,15.3918407 Z"
          fill={color}
        />
        <path
          d="M8.50506488,20.4005275 C7.57578403,20.4005713 6.64645943,20.4039392 5.71717857,20.3993028 C5.16930264,20.3965035 4.83014968,19.8393112 5.0878167,19.370778 C5.23390529,19.1051067 5.47289572,18.9995205 5.76949053,19 C6.64414126,19.0014888 7.51879198,19.0004828 8.39339897,19.0004828 C9.34459311,19.0004828 10.2957435,18.9993455 11.2469377,19.0011389 C11.5923016,19.0017949 11.8495312,19.1696656 11.9570419,19.4551507 C12.13003,19.9146736 11.7963007,20.3963723 11.2929512,20.3990841 C10.3637141,20.4040267 9.43438948,20.4004838 8.50506488,20.4005275"
          fill={color}
        />
        <rect fill={color} x="5" y="10" width="11.2" height="1.4" />
        <rect fill={color} x="5" y="14" width="7.69" height="1.4" />
        <rect fill={color} x="5" y="19" width="7" height="1.4" />
      </g>
    </svg>
  );
}

export function Play({ color }) {
  return (
    <svg width="16px" height="16px" viewBox="0 0 16 16">
      <defs>
        <rect id="Play-path-1" x="0" y="0" width="16" height="16" />
      </defs>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <mask id="Play-mask-2" fill="white">
          <use
            xlinkHref="#Play-path-1"
            transform="translate(8.000000, 8.000000) scale(1, -1) translate(-8.000000, -8.000000) "
          />
        </mask>
        <g
          id="Play-Mask"
          transform="translate(8.000000, 8.000000) scale(1, -1) translate(-8.000000, -8.000000) "
        />
        <path
          d="M11.3294401,7.98412093 L6.01730745,4.12260914 C5.82235899,3.9812928 5.56356279,3.96001021 5.34988566,4.06982834 C5.13450592,4.17879516 5,4.40013402 5,4.63935025 L5,12.3598199 C5,12.6015901 5.13450592,12.8220776 5.34988566,12.9310444 C5.44097511,12.9770148 5.5405776,13 5.64103139,13 C5.7721321,13 5.90493541,12.9582861 6.01730745,12.8757097 L11.3294401,9.01760314 C11.4971468,8.89416416 11.5950521,8.70347222 11.5950521,8.50086204 C11.595898,8.29484664 11.4954442,8.105006 11.3294401,7.98412093 Z"
          id="Path"
          fill={color}
          mask="url(#Play-mask-2)"
        />
      </g>
    </svg>
  );
}

export function Pause({ color }) {
  return (
    <svg width="16px" height="16px" viewBox="0 0 16 16">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path
          d="M5,4 L7.46153846,4 L7.46153846,12 L5,12 L5,4 Z M9.07179487,4 L11.5333333,4 L11.5333333,12 L9.07179487,12 L9.07179487,4 Z"
          fill={color}
        />
      </g>
    </svg>
  );
}

export function Info({ color }) {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24.0 24.0">
      <defs>
        <clipPath id="i0">
          <path d="M12,0 C18.624447,0.00716484761 23.9928352,5.37555305 24,12 C24,18.627417 18.627417,24 12,24 C5.372583,24 0,18.627417 0,12 C0,5.372583 5.372583,0 12,0 Z M12,2 C6.4771525,2 2,6.4771525 2,12 C2,17.5228475 6.4771525,22 12,22 C17.5203344,21.9939376 21.9939376,17.5203344 22,12 C22,6.4771525 17.5228475,2 12,2 Z" />
        </clipPath>
        <clipPath id="i1">
          <path d="M1.5,0 C2.32842712,0 3,0.671572875 3,1.5 C3,2.32842712 2.32842712,3 1.5,3 C0.671572875,3 0,2.32842712 0,1.5 C0,0.671572875 0.671572875,0 1.5,0 Z" />
        </clipPath>
        <clipPath id="i2">
          <path d="M1.5,0 C2.32842712,-1.52179594e-16 3,0.671572875 3,1.5 L3,7.5 C3,8.32842712 2.32842712,9 1.5,9 C0.671572875,9 1.01453063e-16,8.32842712 0,7.5 L0,1.5 C-1.01453063e-16,0.671572875 0.671572875,1.52179594e-16 1.5,0 Z" />
        </clipPath>
      </defs>
      <g clipPath="url(#i0)">
        <polygon points="0,0 24,0 24,24 0,24 0,0" stroke="none" fill={color} />
      </g>
      <g transform="translate(11.0 5.0)">
        <g clipPath="url(#i1)">
          <polygon points="0,0 3,0 3,3 0,3 0,0" stroke="none" fill={color} />
        </g>
      </g>
      <g transform="translate(11.0 10.0)">
        <g clipPath="url(#i2)">
          <polygon points="0,0 3,0 3,9 0,9 0,0" stroke="none" fill={color} />
        </g>
      </g>
    </svg>
  );
}
