import React, { Children, isValidElement, useMemo } from 'react';
import PropTypes from 'prop-types';
import OverlayView from './overlay-view';

function MapMarkers({ children, map, maps }) {
  const markers = useMemo(() => {
    if (!map || !maps) return [];

    return Children.map(children, (child) => {
      if (isValidElement(child)) {
        const latLng = { lat: child.props.lat, lng: child.props.lng };
        // set the map prop on the child component
        return (
          <OverlayView position={latLng} map={map} maps={maps}>
            {child}
          </OverlayView>
        );
      }
      return null;
    });
  }, [children, map, maps]);

  return <div>{markers}</div>;
}

MapMarkers.defaultProps = {
  map: undefined,
};

MapMarkers.propTypes = {
  /**
   * The Markers on the Map.
   * @type {ReactNode}
   * @required
   */
  children: PropTypes.node.isRequired,
  /**
   * The Google Maps instance.
   * @type {object}
   * @required
   */
  map: PropTypes.shape(),
  /**
   * The Google Maps API.
   * @type {object}
   * @required
   */
  maps: PropTypes.shape().isRequired,
};

export default MapMarkers;
