import React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Color from 'color';

import { mapAction } from 'core/map';

// import { LinkBtn } from 'components/global/Button';
// import { HorizontalArrow } from 'components/icon/Arrow';
import Close from 'components/icon/Close';
import ListControl from 'container/layout/Footer/ListControl';

function Playlist() {
  const dispatch = useDispatch();
  const { t } = useTranslation(['common']);
  const { onload } = useSelector((state) => state.global);
  const { showList, audioList } = useSelector((state) => state.map);

  const handleBack = () => dispatch(mapAction.toogleList(false));

  return (
    <Wrap
      className={`${!showList ? ' j-hidelist' : ''}${
        onload ? ' j-onload' : ''
      }`}
    >
      {/* <GoBack onClick={handleBack}>
        <HorizontalArrow width="7px" />
        {t('playlist.back')}
      </GoBack> */}

      <Title>
        {t('playlist.title')}
        <Count>
          {t('playlist.total')} {audioList.length}
        </Count>
        <ListClose onClick={handleBack} />
      </Title>

      <ListWrap>
        {audioList.map((list) => (
          <ListControl audioData={list} key={`${list.id}${list.title}`} />
        ))}
      </ListWrap>
    </Wrap>
  );
}

Playlist.propTypes = {};

Playlist.defaultProps = {};

export default Playlist;

const Wrap = styled.div`
  position: fixed;
  left: 0;
  bottom: 40px;
  width: 100%;
  padding: 20px 20px 0;
  opacity: 1;
  pointer-events: auto;
  transition: opacity ease-in 0.3s;
  background-color: ${({ theme }) => theme.background};
  z-index: ${({ theme }) => theme.zIndex.lists};
  &.j-hidelist {
    opacity: 0;
    pointer-events: none;
  }
  @media (orientation: portrait) {
    //豎屏CSS
    height: calc(57% + 12px);
    max-height: 305px;
  }
  @media (orientation: landscape) {
    //橫屏CSS
    height: calc(100% - 40px);
  }
  @media (min-width: ${({ theme }) => theme.breakpoint.lg}) {
    width: 210px;
    height: 286px;
    left: unset;
    bottom: unset;
    top: 32px;
    right: 32px;
    padding: 0;
    opacity: 1;
    pointer-events: auto;
    transform: translateX(calc(100% + 32px));
    transition: transform 0.5s ease-in-out 1.3s, right ease-in-out 0.3s,
      opacity 0.5s;
    z-index: ${({ theme }) => theme.zIndex.listsDesktop};
    &.j-onload {
      transform: translateX(0);
    }
    &.j-hidelist {
      right: -250px;
    }
  }
`;

// const GoBack = styled(LinkBtn)`
//   font-size: 12px;
//   line-height: 18px;
//   margin-bottom: 12px;
//   & > ${HorizontalArrow} {
//     margin: 0 8px 1px 0;
//     &::before,
//     &::after {
//       background-color: ${({ theme }) => theme.font};
//     }
//   }
//   @media (min-width: ${({ theme }) => theme.breakpoint.lg}) {
//     & {
//       display: none;
//     }
//   }
// `;

const Title = styled.h4`
  position: relative;
  font-size: 14px;
  line-height: 20px;
  padding: 8px;
  margin: 0;
  color: ${({ theme }) => theme.background};
  background-color: ${({ theme }) => Color(theme.primary).alpha(0.7).string()};
`;

const Count = styled.small`
  font-size: 12px;
  font-weight: 100;
  margin-left: 16px;
`;

const ListWrap = styled.ul`
  width: 100%;
  height: 250px;
  list-style: none;
  padding: 0 8px 8px;
  margin: 0;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.background};
  @media (min-width: ${({ theme }) => theme.breakpoint.lg}) {
    & {
      max-height: 242px;
    }
  }
`;

const ListClose = styled(Close)`
  display: none;
  @media (min-width: ${({ theme }) => theme.breakpoint.lg}) {
    & {
      position: absolute;
      display: block;
      top: 5px;
      right: 5px;
      width: 28px;
      height: 28px;
      & > i {
        &::before,
        &::after {
          background-color: ${({ theme }) => theme.background};
        }
      }
    }
  }
`;
