/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { useDispatch, useSelector } from 'react-redux';

import { mapAction } from 'core/map';
import { globalAction } from 'core/global';

import { LinkBtn } from 'components/global/Button';
import { Play, Pause } from 'components/icon/Svg';

function ListControl({ audioData }) {
  const dispatch = useDispatch();
  const { fetchedAudios, playingAudio } = useSelector((state) => state.audio);
  const {
    selectedDetail: { podcast: detailPodcast = '' },
  } = useSelector((state) => state.map);
  const { podcast: audioPodcast = '', playerState = 'init' } =
    fetchedAudios.find((audio) => audio.podcast === playingAudio) || {};

  const handlePlay = () => {
    dispatch(
      globalAction.sendGtagEvent('playlist_tryto', 'related', audioData.id)
    );
    dispatch(mapAction.fetchSelectedDetail(audioData, true));
  };

  const handleDetail = () => {
    dispatch(
      globalAction.sendGtagEvent('playlist_tryto', 'related', audioData.id)
    );
    dispatch(mapAction.fetchSelectedDetail(audioData));
  };

  return (
    <Wrap
      className={`${detailPodcast === audioData.podcast ? ' j-detail' : ''}${
        audioPodcast === audioData.podcast ? ' j-audio' : ''
      }`}
    >
      <PlayBtn onClick={handlePlay}>
        {audioPodcast === audioData.podcast ? (
          playerState !== 'play' ? (
            <Play />
          ) : (
            <Pause />
          )
        ) : (
          <Play />
        )}
      </PlayBtn>

      <LinkBtn onClick={handleDetail}>
        {audioData.title ? audioData.title : 'undefined'}
      </LinkBtn>
    </Wrap>
  );
}

ListControl.propTypes = { audioData: PropTypes.shape() };

ListControl.defaultProps = { audioData: undefined };

export default ListControl;

const Wrap = styled.li`
  display: flex;
  align-items: flex-start;
  font-size: 12px;
  font-weight: 100;
  line-height: 18px;
  padding: 8px 0;
  border-bottom: 1px solid #a0a0a0;
  & svg path {
    fill: ${({ theme }) => theme.font};
  }
  &.j-detail {
    color: ${({ theme }) => theme.primary};
  }
  &.j-audio {
    & svg path {
      fill: ${({ theme }) => theme.primary};
    }
  }
  > button {
    text-align: left;
  }
`;

const PlayBtn = styled(LinkBtn)`
  display: none;
  width: 16px;
  height: 16px;
  margin: -1px 4px 0 0;
  @media (min-width: ${({ theme }) => theme.breakpoint.lg}) {
    display: block;
  }
`;
