import mapAction from './action';

export const mapState = {
  showMenu: false,
  showList: typeof window !== 'undefined' && window.innerWidth > 991,
  showDetail: true,
  miniDetail: true,
  layoutData: {},
  audioList: [],
  selectedDetail: {},
  mapCenter: { lat: '0', lng: '0' },
  currentMap: '',
};

// eslint-disable-next-line default-param-last
const reducer = (state = mapState, action) => {
  const { type, payload } = action;

  switch (type) {
    case mapAction.TOGGLE_MENU:
      return {
        ...state,
        showMenu: !state.showMenu,
      };

    case mapAction.TOGGLE_MINI_STATE:
      return {
        ...state,
        miniDetail: !state.miniDetail,
      };

    case mapAction.TOGGLE_LIST:
      return {
        ...state,
        showList: payload,
      };

    case mapAction.SET_DETAIL_STATE:
      return {
        ...state,
        showDetail: payload,
      };

    case mapAction.SET_DETAIL_INFO:
      return {
        ...state,
        selectedDetail: payload,
      };

    case mapAction.SET_MAP_CENTER:
      // eslint-disable-next-line no-case-declarations
      const { latitude, longitude } = payload;

      return {
        ...state,
        mapCenter: { lat: Number(latitude), lng: Number(longitude) },
      };

    case mapAction.SET_SELECTED_MAP:
      return {
        ...state,
        currentMap: payload,
      };

    case mapAction.SET_AUDIO_LIST:
      return {
        ...state,
        audioList: payload,
      };

    case mapAction.SET_LAYOUT_DATA:
      return {
        ...state,
        layoutData: { ...state.layoutData, ...payload },
      };

    default:
      return state;
  }
};

export default reducer;
