import { call, put } from 'redux-saga/effects';

import globalAction from 'core/global/action';
import { errorMessageHelper } from 'core/helper/util';

/**
 * fetch api entity
 *
 * @export
 * @param {Function} apiFunction
 * @param {String} [actionType=null] put ${actionType}_RESPONSE, no return respnse
 * @param {Object} payload for apiFunction
 * @returns
 */
// eslint-disable-next-line default-param-last
function* fetchEntity(apiFunction, actionType = null, payload) {
  try {
    // const { currentUser: { customClaims: { role } = {} } = {} } = yield select(
    //   (state) => state.user
    // );

    yield put({ type: globalAction.FETCH_REQUEST });

    const response = yield call(apiFunction, payload);

    yield put({ type: globalAction.FETCH_FINISH });

    if (actionType) {
      yield put({ type: `${actionType}_RESPONSE`, payload: response });
    } else {
      return response;
    }

    return false;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.warn('errorMessageHelper', err);
    const { response } = err;

    yield put({ type: globalAction.FETCH_FINISH });

    if (response) {
      const { code = undefined, status, data = {} } = response;
      if (data && data.lists) {
        return { status, data };
      }
      const errorMessage =
        errorMessageHelper(
          status,
          code,
          data && data.message ? data.message : ''
        ) || {};

      yield put(
        globalAction.setAlert({
          theme: 'error',
          message: errorMessage,
        })
      );
    } else {
      yield put(
        globalAction.setAlert({
          theme: 'error',
          message: err.message,
        })
      );
    }

    return null;
  }
}

export default fetchEntity;
