/* eslint-disable default-param-last */
import { defaultLanguage } from 'config/i18n';
import globalAction from './action';

export const globalState = {
  lightTheme: false,
  alert: null,
  setRouter: null,
  fetch: 1,
  formError: null,
  devLogin: false,
  onload: false,
  language: defaultLanguage,
};

const reducer = (state = globalState, action) => {
  const { type, payload } = action;

  switch (type) {
    case globalAction.FETCH_REQUEST:
      return {
        ...state,
        fetch: state.fetch + 1,
      };

    case globalAction.FETCH_FINISH:
      return {
        ...state,
        fetch: state.fetch === 0 ? state.fetch : state.fetch - 1,
      };

    case globalAction.SET_ALERT:
      return {
        ...state,
        alert: payload,
      };

    case globalAction.SET_ROUTER:
      return {
        ...state,
        setRouter: payload,
      };

    case globalAction.SWITCH_THEME:
      return {
        ...state,
        lightTheme: !state.lightTheme,
      };

    case globalAction.VALIDATE_RESULT:
      return {
        ...state,
        formError: payload,
      };

    case globalAction.DEV_USER_LOGIN:
      return {
        ...state,
        devLogin: payload,
      };

    case globalAction.DOM_READY:
      return {
        ...state,
        onload: payload,
        fetch: state.fetch - 1,
      };

    case globalAction.SET_LANGUAGE:
      return {
        ...state,
        language: payload,
      };

    default:
      return state;
  }
};

export default reducer;
