import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';

import rootReducer from './rootReducer';
import rootSaga from './rootSaga';

// -----------------------------------------------------------------------------
// Enhancer/Middleware
// -----------------------------------------------------------------------------
const middlewares = [];
const sagaMiddleware = createSagaMiddleware();

const composeEnhancers = composeWithDevTools({
  // Specify name here, actionsBlacklist, actionsCreators and other options if needed
});

middlewares.push(sagaMiddleware);

const enhancer = composeEnhancers(
  // Add middlewares:
  applyMiddleware(...middlewares)
);

// -----------------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------------

const store = createStore(rootReducer, enhancer);

sagaMiddleware.run(rootSaga);

export default store;
