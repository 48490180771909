import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';

import ErrorMessage from 'components/form/ErrorMessage';
import { VerticalArrow } from 'components/icon/Arrow';

function Select({
  id,
  size,
  width,
  name,
  labelText,
  value,
  placeholder,
  disabled,
  className,
  optionData,
  onChange,
  onFocus,
  message,
  submited,
}) {
  const handleSelect = (e) => {
    const selectedData = optionData.find(
      (obj) => obj.value === e.currentTarget.dataset.optionValue
    );

    if (
      !disabled &&
      (currentOption === '' || selectedData.value !== currentOption.value)
    ) {
      setCurrentOption(selectedData);
    }
    setFocus(false);
  };

  const handleToggleFocus = (e) => {
    e.stopPropagation();

    if (!disabled) {
      setFocus(!focus);
    }

    if (!focus && typeof onFocus === 'function') {
      onFocus(e);
    }
  };

  const handleOnBlur = (e) => {
    e.stopPropagation();

    setFocus(false);
  };

  const [focus, setFocus] = useState(false);
  const [currentOption, setCurrentOption] = useState('');

  useEffect(() => {
    if (value) {
      const selectedData = optionData.find((obj) => obj.value === value) || {};

      if (selectedData.value !== currentOption.value) {
        setCurrentOption(selectedData);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (currentOption !== '') {
      onChange(name, currentOption.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOption]);

  return (
    <Wrap
      id={id}
      width={width}
      className={`${className}${submited && message ? ' formError' : ''}`}
      disabled={disabled}
      onBlur={handleOnBlur}
      tabIndex="0"
    >
      {labelText ? <Label htmlFor={id}>{labelText}</Label> : null}

      <Control
        size={size}
        onClick={handleToggleFocus}
        className={focus ? 'active' : ''}
      >
        <Value>
          {currentOption ? (
            <>
              {currentOption.icon ? (
                <Icon src={currentOption.icon} alt={currentOption.name} />
              ) : null}

              <Name>{currentOption.name}</Name>
            </>
          ) : (
            placeholder
          )}
        </Value>

        <VerticalArrow className={focus ? 'active' : ''} />
      </Control>

      <OptionWrap size={size} className={focus ? 'active' : ''}>
        {optionData.map((data) => (
          <Option
            className={data.value === currentOption.value ? 'active' : ''}
            key={`${name}${data.value}`}
            value={data.value}
            data-option-value={data.value}
            onClick={handleSelect}
          >
            {data.icon ? <Icon src={data.icon} alt={data.name} /> : null}

            <Name>{data.name}</Name>
          </Option>
        ))}
      </OptionWrap>

      <ErrorMessage
        className={submited && message ? 'active' : ''}
        type="select"
      >
        {message}
      </ErrorMessage>
    </Wrap>
  );
}

Select.propTypes = {
  id: PropTypes.string,
  size: PropTypes.oneOf(['large', 'default', 'small']),
  width: PropTypes.string,
  name: PropTypes.string,
  labelText: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  optionData: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      name: PropTypes.string.isRequired,
      icon: PropTypes.string,
    })
  ).isRequired,
  message: PropTypes.string,
  submited: PropTypes.bool,
};

Select.defaultProps = {
  id: undefined,
  size: 'default',
  width: undefined,
  name: undefined,
  labelText: undefined,
  value: undefined,
  disabled: false,
  className: '',
  onFocus: undefined,
  message: undefined,
  submited: true,
};

export default Select;

const Label = styled.label`
  font-size: 14px;
  font-weight: bold;
  line-height: 24px;
`;

const Wrap = styled.div`
  position: relative;
  height: fit-content;
  width: ${({ width }) => width || '100%'};
  &:focus {
    outline: none;
  }
  > select {
    display: block;
  }
  ${({ disabled }) =>
    disabled
      ? css`
          & ${Control} {
            cursor: text;
          }
          & ${VerticalArrow} {
            opacity: 0;
          }
        `
      : null}
  & ${ErrorMessage} {
    position: absolute;
    margin: 0;
    top: 100%;
    left: 0;
  }
  &.formError {
    margin-bottom: 24px;
  }
`;

const Control = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  /* border-radius: 4px; */
  background-color: ${({ theme }) => theme.background};
  cursor: pointer;
  z-index: 1;
  ${({ size }) =>
    size === 'large'
      ? css`
          height: 40px;
          border: 2px solid ${({ theme }) => theme.primary};
        `
      : null}
  ${({ size }) =>
    size === 'default'
      ? css`
          height: 30px;
          border: 1px solid ${({ theme }) => theme.primary};
        `
      : null}
  & > ${VerticalArrow} {
    width: 8px;
    height: 8px;
    margin: 4px;
  }
  &.active {
    z-index: 3;
  }
`;

const Value = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: calc(100% - 18px);
  min-width: 0;
  color: ${({ theme }) => theme.primary};
  font-size: 12px;
`;

const Name = styled.span`
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  font-size: 12px;
  color: inherit;
`;

const OptionWrap = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 100%;
  pointer-events: none;
  opacity: 0;
  color: ${({ theme }) => theme.primary};
  transform: translate(0, -50%);
  transition: opacity 0.3s, transform ease-in 0.4s;
  background-color: ${({ theme }) => theme.background};
  cursor: pointer;
  &.active {
    opacity: 1;
    pointer-events: auto;
    transform: translate(0, 0);
    transition: opacity 0.5s, transform ease-out 0.3s;
    z-index: 2;
  }
  ${({ size }) =>
    size === 'large'
      ? css`
          border: 2px solid ${({ theme }) => theme.primary};
        `
      : null}
  ${({ size }) =>
    size === 'default'
      ? css`
          border: 1px solid ${({ theme }) => theme.primary};
        `
      : null}
`;

const Option = styled.div`
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 30px;
  padding: 8px;
  font-size: 12px;
  line-height: 2;
  &:hover,
  &:active,
  &:focus {
    color: ${({ theme }) => theme.background};
    background-color: ${({ theme }) => theme.primary};
  }
  &.active {
    color: ${({ theme }) => theme.background};
    background-color: ${({ theme }) => theme.primary};
  }
`;

const Icon = styled.img`
  box-sizing: border-box;
  width: 28px;
  height: auto;
  margin-right: 10px;
  padding: 3px;
`;
