import { combineReducers } from 'redux';

import { globalReducer } from './global';
import { audioReducer } from './audio';
import { mapReducer } from './map';

export default combineReducers({
  global: globalReducer,
  audio: audioReducer,
  map: mapReducer,
});
