import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { useDispatch, useSelector } from 'react-redux';

import { globalAction } from 'core/global';

import Topic from 'container/layout/Header/Topic';
import Topbar from 'container/layout/Header/Topbar';

function Header() {
  const dispatch = useDispatch();
  const { onload } = useSelector((state) => state.global);
  const [showTopic, setShowTopic] = useState(
    typeof window !== 'undefined' && window.innerWidth > 991
  );

  const handleToggle = () => {
    if (showTopic === false) {
      dispatch(globalAction.sendGtagEvent('curiosity', 'top-menu'));
    }
    setShowTopic(!showTopic);
  };

  return (
    <Wrap className={`${onload ? ' j-onload' : ''}`}>
      <Topbar handleClick={handleToggle} />

      <Topic showTopic={showTopic} handleClick={handleToggle} />
    </Wrap>
  );
}

export default Header;

const Wrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  min-width: 375px;
  padding: 20px 20px 0;
  color: #fff;
  z-index: 1;
  opacity: 0;
  transform: translateY(-100%);
  transition: transform 0.5s ease-in-out 1.3s, opacity 0.5s linear 1.3s;
  &.j-onload {
    opacity: 1;
    transform: translateY(0);
  }
`;
