import styled, { css } from 'styled-components/macro';

export const VerticalArrow = styled.i`
  display: inline-block;
  position: relative;
  width: ${({ width }) => (width ? `${width}` : '10px')};
  height: 100%;
  transform: rotateX(0);
  transform-style: preserve-3d;
  transition: 0.2s transform;
  ${({ direction }) =>
    direction === 'up'
      ? css`
          transform: rotateX(180deg);
        `
      : css`
          transform: rotateX(0);
        `}
  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 85%;
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.primary};
  }
  &::before {
    right: 49%;
    transform: rotate(45deg);
    transform-origin: right;
    border-radius: 500px 0 0 500px;
  }
  &::after {
    left: 49%;
    transform: rotate(-45deg);
    transform-origin: left;
    border-radius: 0 500px 500px 0;
  }
  &.active {
    ${({ direction }) =>
      direction === 'up'
        ? css`
            transform: rotateX(0);
          `
        : css`
            transform: rotateX(180deg);
          `}
  }
`;

export const HorizontalArrow = styled.i`
  display: inline-block;
  position: relative;
  width: ${({ width }) => (width ? `${width}` : '10px')};
  height: ${({ width }) => (width ? `${width}` : '10px')};
  transform: rotateX(0);
  transform-style: preserve-3d;
  transition: 0.2s transform;
  ${({ direction }) =>
    direction === 'right'
      ? css`
          transform: rotateX(180deg);
        `
      : css`
          transform: rotateX(0);
        `}
  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.primary};
  }
  &::before {
    bottom: -23%;
    right: 32%;
    transform: rotate(45deg);
    transform-origin: right;
    border-radius: 500px 0 0 500px;
  }
  &::after {
    top: 46%;
    right: -2%;
    transform: rotate(-45deg);
    transform-origin: left;
    border-radius: 0 500px 500px 0;
  }
  &.active {
    ${({ direction }) =>
      direction === 'right'
        ? css`
            transform: rotateX(0);
          `
        : css`
            transform: rotateX(180deg);
          `}
  }
`;

export default undefined;
