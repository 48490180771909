import styled, { css } from 'styled-components/macro';

const ErrorMessage = styled.p`
  display: none;
  color: ${({ theme }) => theme.error};
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0.2px;
  line-height: 1.8;
  text-align: left;
  &.active {
    display: block;
  }
  ${({ type }) =>
    type === 'select'
      ? css`
          width: 100%;
          left: 0 !important;
        `
      : null}
`;

export default ErrorMessage;
