import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { useSwipeable } from 'react-swipeable';
import { useSelector } from 'react-redux';

import env from 'config/env';
import { useWindowSize } from 'core/helper/hook';

import { LinkBtn } from 'components/global/Button';
import Image from 'components/global/Image';

const itemSizeSetting = {
  mobile: {
    width: 121 + 12,
    height: 75,
  },
  desktop: {
    width: 182 + 16,
    height: 111,
  },
};

function Carousel({ className, children = [] }) {
  const wrapRef = useRef(null);
  const { onload } = useSelector((state) => state.global);
  const [itemSize, setItemSize] = useState(itemSizeSetting.mobile);
  const [isTicking, setIsTicking] = useState(false);
  const [isBeyond, setIsBeyond] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const { width } = useWindowSize();

  const { length } = children;
  const copyLists = [...children].map((obj, i) => {
    const newObj = { ...obj };
    newObj.order = i;

    return newObj;
  });

  const handleNext = () => {
    if (length - 1 > activeIndex) {
      setActiveIndex(activeIndex + 1);
    }
  };

  const handlePrev = () => {
    if (activeIndex > 0) {
      setActiveIndex(activeIndex - 1);
    }
  };

  const getListStyles = (order) => {
    const styles = {
      transform: `translateX(${(order - activeIndex) * itemSize.width}px)`,
    };

    return styles;
  };

  // eslint-disable-next-line no-unused-vars
  const handleSwipe = useSwipeable({
    onSwipedLeft: handleNext,
    onSwipedRight: handlePrev,
    delta: 10, // min distance(px) before a swipe starts. *See Notes*
    preventDefaultTouchmoveEvent: false, // call e.preventDefault *See Details*
    trackTouch: true, // track touch input
    trackMouse: true, // track mouse input
    rotationAngle: 0, // set a rotation angle
  });

  useEffect(() => {
    if (isTicking) {
      setTimeout(setIsTicking(false), 300);
    }
  }, [isTicking]);

  useEffect(() => {
    const currentSize =
      width > 991 ? itemSizeSetting.desktop : itemSizeSetting.mobile;
    const gobeyond =
      width > 991
        ? currentSize.width * length > width * 0.6 - 16
        : currentSize.width * length > width - 40 - 12;

    if (itemSize.width !== currentSize.width) {
      setItemSize(currentSize);
    }
    if (isBeyond !== gobeyond) {
      setIsBeyond(gobeyond);
    }
    setActiveIndex(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, length]);

  return (
    <Wrap className={`${className}${onload ? ' j-onload' : ''}`}>
      <Content ref={wrapRef}>
        <Slide total={length} w={itemSize.width} h={itemSize.height}>
          {copyLists.map((list, index) => {
            const styles = getListStyles(list.order);

            return (
              <Item
                key={`carousel${list.order}`}
                w={itemSize.width}
                h={itemSize.height}
                style={{ ...styles }}
              >
                {list}
              </Item>
            );
          })}
        </Slide>

        <BtnWrap className={isBeyond ? '' : 'j-hide'}>
          <LinkBtn onClick={handlePrev}>
            <Image
              src={`${env.routerBaseName}/asset/image/icon/prev.svg`}
              alt="prev icon"
            />
          </LinkBtn>
          <LinkBtn onClick={handleNext}>
            <Image
              src={`${env.routerBaseName}/asset/image/icon/next.svg`}
              alt="next icon"
            />
          </LinkBtn>
        </BtnWrap>
      </Content>
    </Wrap>
  );
}

Carousel.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

Carousel.defaultProps = { children: [], className: '' };

export default Carousel;

const Item = styled.div`
  position: absolute;
  display: inline-block;
  width: ${({ w }) => w}px;
  /* height: ${({ h }) => h}px; */
  aspect-ratio: 16 / 9;
  padding: 0 6px;
  box-sizing: border-box;
  @media (min-width: ${({ theme }) => theme.breakpoint.lg}) {
    padding: 0 8px;
  }
`;

const Wrap = styled.div`
  position: relative;
  overflow: hidden;
  margin-bottom: 16px;
  &.j-onload ${Item} {
    transition: transform 0.3s ease-in-out;
  }
  @media (min-width: ${({ theme }) => theme.breakpoint.lg}) {
    margin-bottom: 0;
  }
`;

const Content = styled.div`
  width: 100%;
`;

const Slide = styled.div`
  display: flex;
  flex-wrap: nowrap;
  width: ${({ total, w }) => w * total}px;
  height: ${({ h }) => h}px;
  margin: 0 -6px 16px;
  @media (min-width: ${({ theme }) => theme.breakpoint.lg}) {
    & {
      margin: 0 -8px 16px;
    }
  }
`;

const BtnWrap = styled.div`
  &.j-hide {
    display: none;
  }
  & > button {
    margin-right: 24px;
  }
`;
