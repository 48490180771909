import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ThemeProvider } from 'styled-components/macro';
import { useSelector } from 'react-redux';

import themeConfig from 'config/theme';

import Wrapper from 'container/layout/Wrapper';
import Alert from 'container/global/Alert';
import Loading from 'components/global/Loading';
import Home from 'view/Home';
import FourOFour from 'view/404';

import NormalizeStyle from 'styles/normalize';
import GlobalStyle from 'styles/global';

function App() {
  const { fetch, alert, lightTheme } = useSelector((state) => state.global);
  const { title: alertTitle, theme, message, autoClose } = alert || {};

  return (
    <>
      {/* style */}
      <NormalizeStyle />
      <GlobalStyle theme={themeConfig[lightTheme ? 'light' : 'dark']} />
      <ThemeProvider theme={themeConfig[lightTheme ? 'light' : 'dark']}>
        <Wrapper>
          <Loading active={fetch > 0} />

          {alert ? (
            <Alert title={alertTitle} theme={theme} autoClose={autoClose}>
              {message}
            </Alert>
          ) : null}
          <Routes>
            <Route path=":lang/soundmap/:pid/:slug" element={<Home />} exact />
            <Route path="/soundmap/:pid/:slug" element={<Home />} exact />
            <Route path="/groups/:slug/:pid" element={<Home />} exact />

            <Route path="*" element={<FourOFour />} />
          </Routes>
          {/* TODO: when redirect domain finished opne it <Redirect to="https://support.gogoro.com/tw/404" /> */}
        </Wrapper>
      </ThemeProvider>
    </>
  );
}

export default App;
