const action = {
  FETCH_REQUEST: 'FETCH_REQUEST',
  FETCH_FINISH: 'FETCH_FINISH',
  SET_ROUTER: 'SET_ROUTER',
  SET_ALERT: 'SET_ALERT',
  SWITCH_THEME: 'SWITCH_THEME',
  VALIDATE_FORM: 'VALIDATE_FORM',
  VALIDATE_RESULT: 'VALIDATE_RESULT',
  DEV_USER_LOGIN: 'DEV_USER_LOGIN',
  DOM_READY: 'DOM_READY',
  CHANGE_LANGUAGE: 'CHANGE_LANGUAGE',
  SET_LANGUAGE: 'SET_LANGUAGE',
  FETCH_INIT_DATA: 'FETCH_INIT_DATA',
  SEND_GTAG_EVENT: 'SEND_GTAG_EVENT',

  validateForm(schema, data) {
    return {
      type: this.VALIDATE_FORM,
      payload: { schema, data },
    };
  },
  setAlert(
    data: Object = {
      title: String,
      theme: String, // 'success', 'info'(default), 'error
      message: String,
      autoClose: Number,
    }
  ) {
    return {
      type: this.SET_ALERT,
      payload: data,
    };
  },
  setRouter(data) {
    return {
      type: this.SET_ROUTER,
      payload: data,
    };
  },
  changeLanguage(lang, reload = false) {
    return {
      type: this.CHANGE_LANGUAGE,
      payload: { lang, reload },
    };
  },
  sendGtagEvent(
    actionName: String,
    category: String,
    label: String,
    value: String
  ) {
    return {
      type: this.SEND_GTAG_EVENT,
      payload: { actionName, category, label, value },
    };
  },
};

export default action;
