import styled, { css } from 'styled-components/macro';

const Image = styled.img`
  ${({ type }) =>
    type === 'cover'
      ? css`
          width: 100%;
          height: 100%;
          object-fit: cover;
        `
      : css`
          width: 100%;
          height: auto;
        `}
`;

export default Image;
