const action = {
  FETCH_AUDIO: 'FETCH_AUDIO',
  SET_FETCHED_AUDIO: 'SET_FETCHED_AUDIO',
  SET_PLAYING_AUDIO: 'SET_PLAYING_AUDIO',
  SET_PLAYER_STATE: 'SET_PLAYER_STATE',
  HANDLE_DURATION: 'HANDLE_DURATION',
  HANDLE_PROGRESS: 'HANDLE_PROGRESS',
  HANDLE_PAUSE: 'HANDLE_PAUSE',
  HANDLE_PLAY: 'HANDLE_PLAY',
  PLAY_AFTER_FETCH: 'PLAY_AFTER_FETCH',

  fetchAudio(audioObj: Object) {
    return {
      type: this.FETCH_AUDIO,
      payload: audioObj,
    };
  },
  setFetchedAudio(audioList: Array<Object>) {
    return {
      type: this.SET_FETCHED_AUDIO,
      payload: audioList,
    };
  },
  setPlayingAudio(url: String) {
    return {
      type: this.SET_PLAYING_AUDIO,
      payload: url,
    };
  },
  setPlayerState(audioObj: Object, playerState: String) {
    return {
      type: this.SET_PLAYER_STATE,
      payload: { audioObj, playerState },
    };
  },
  playAfterFetch(url: String) {
    return {
      type: this.PLAY_AFTER_FETCH,
      payload: url,
    };
  },
};

export default action;
