import { createGlobalStyle } from 'styled-components/macro';

const globalStyle = createGlobalStyle`
html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: ${({ theme }) => theme.fontFamily};
  color: ${({ theme }) => theme.font};
  overscroll-behavior: none;
}

body {
  padding-top: constant(safe-area-inset-top);
  padding-right: constant(safe-area-inset-right);
  padding-bottom: constant(safe-area-inset-bottom);
  padding-left: constant(safe-area-inset-left);
}

#root {
  height: 100%;
}

*{
  box-sizing: border-box;
  & :focus {
    outline: none;
  }
}

.j{
  &-hide {
    display: none !important;
  }
}

#__next {
  width: 100%;
  height: 100%;
}

.google-map {
  & > div {
    background-color: ${({ theme }) => theme.mapBackground} !important;
  }
}
`;

export default globalStyle;
