import React, { useEffect, useRef } from 'react';
import styled from 'styled-components/macro';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { globalAction } from 'core/global';

import Audio from 'components/global/Audio';
import Map from 'container/Map';

function Home() {
  const fetchInitData = useRef(false);
  const { lang, pid, slug } = useParams();
  const dispatch = useDispatch();
  const { fetchedAudios } = useSelector((state) => state.audio);

  useEffect(() => {
    if (!fetchInitData.current && pid) {
      fetchInitData.current = true;

      dispatch({
        type: globalAction.FETCH_INIT_DATA,
        payload: {
          lang,
          pid,
          slug,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pid]);

  return (
    <Content>
      <Map />

      {fetchedAudios.length
        ? fetchedAudios.map((audio) => (
            <Audio
              key={audio.podcast}
              src={audio.podcastUrl}
              audioObj={audio}
            />
          ))
        : null}
    </Content>
  );
}

export default Home;

const Content = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;
