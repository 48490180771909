import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import env from 'config/env';
import { globalAction } from 'core/global';
import { arrShuffle } from 'core/helper/util';

function Custom404() {
  const dispatch = useDispatch();
  const { t } = useTranslation(['error']);
  const [uLost, setULost] = useState([...'404ULOST!']);

  useEffect(() => {
    dispatch({ type: globalAction.DOM_READY, payload: true });
    const randomize = setInterval(() => setULost(arrShuffle(uLost)), 1500);

    return () => clearInterval(randomize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uLost]);

  return (
    <Wrap>
      <Title>
        {uLost.map((str, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Text key={`404${str}${index}`}>{str}</Text>
        ))}
        <Content>
          {t('lostInfo')}{' '}
          <CustomLink href={env.indexUrl}>{t('backhome')}</CustomLink>
        </Content>
      </Title>
    </Wrap>
  );
}

export default Custom404;

const Wrap = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme }) => theme.background};
  z-index: 999;
`;

const Title = styled.h1`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 75vmin;
  display: flex;
  flex-wrap: wrap;
`;

const Content = styled.div`
  text-align: center;
  font-size: 16px;
  margin: 0 auto;
  font-weight: normal;
`;

const CustomLink = styled.a`
  display: inline-block;
  color: ${({ theme }) => theme.primary};
`;

const Text = styled.p`
  display: block;
  flex: 1 0 25vmin;
  text-align: center;
  color: ${({ theme }) => theme.font};
  font-size: 20vmin;
  text-transform: uppercase;
  height: 25vmin;
  margin: 0;
`;
