import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import env from 'config/env';
import { mapAction } from 'core/map';
import { globalAction } from 'core/global';

import Image from 'components/global/Image';
import Select from 'components/form/Select';
import { LinkBtn } from 'components/global/Button';
import { Info as InfoIcon } from 'components/icon/Svg';

function Topbar({ handleClick }) {
  const { isIframe, isGroups } = env;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    currentMap,
    layoutData: {
      mapPlanOptions = [
        {
          name: '{mapPlanOptions.name}',
          value: '{mapPlanOptions.value}',
        },
      ],
    } = {},
  } = useSelector((state) => state.map);
  const { t } = useTranslation(['common']);

  const setFormData = (name, value) =>
    dispatch(mapAction.changeMap(value, navigate));

  const handleFocus = () =>
    dispatch(globalAction.sendGtagEvent('tryto', 'show-box'));

  return (
    <Wrap>
      <Logo
        src={`${env.routerBaseName}/asset/image/icon/logo.svg`}
        alt="logo"
      />

      <SelectWrap>
        <Label>{t('header.select.label')}</Label>

        <Select
          disabled={isIframe && !isGroups}
          width="100%"
          placeholder={t('header.select.placeholder')}
          name="selectedMapPlan"
          value={currentMap || ''}
          onChange={setFormData}
          onFocus={handleFocus}
          optionData={mapPlanOptions}
        />
      </SelectWrap>

      <InfoBtn onClick={handleClick}>
        <InfoIcon />
      </InfoBtn>
    </Wrap>
  );
}

export default Topbar;

Topbar.propTypes = {
  handleClick: PropTypes.func,
};

Topbar.defaultProps = {
  handleClick: undefined,
};

const Wrap = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  z-index: 4;
  @media (min-width: ${({ theme }) => theme.breakpoint.lg}) {
    & {
      /* align-items: flex-end; */
    }
  }
`;

const Logo = styled(Image)`
  width: 56px;
  margin-bottom: 8px;
  @media (min-width: ${({ theme }) => theme.breakpoint.lg}) {
    & {
      width: 80px;
    }
  }
`;

const SelectWrap = styled.div`
  width: 292px;
`;

const Label = styled.p`
  font-size: 12px;
  line-height: 18px;
  margin: 0 0 4px;
`;

const InfoBtn = styled(LinkBtn)`
  position: absolute;
  bottom: 2px;
  right: 0;
  width: 24px;
  height: 24px;
  > svg {
    fill: ${({ theme }) => theme.primary};
  }
`;
