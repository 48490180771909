/* eslint-disable no-param-reassign */
import axios from 'axios';
import Cookies from 'js-cookie';

import env from 'config/env';

const rootApi = axios.create({
  baseURL: env.functions_api,
  responseType: 'json',
});

rootApi.interceptors.request.use(
  (config) => {
    const SessID = Cookies.get('PHPSESSID');

    if (SessID) {
      config.headers.Cookie = `PHPSESSID=${SessID}`;
    }

    config.headers['Content-Type'] = `application/x-www-form-urlencoded`;

    return config;
  },
  (error) => Promise.reject(error)
);

export default rootApi;
