const global = {
  maxWidth: '',
  popupWidth: '480px',
  padding: '',
  error: '#FF1923',
  warning: '#C0DF16',
  success: '#28E1A5',
  info: '#00d7ff',
  disabled: '#666666',
  mapBackground: '#000',
  fontFamily: `"Noto Sans TC", "HelveticaNeue", Helvetica, Arial, "Lucida Grande", sans- serif`,
  breakpoint: {
    sm: '576px', // 36rem
    md: '768px', // 48rem
    lg: '992px', // 62rem
    xl: '1200px', // 75rem
    xxl: '1400px',
  },
  zIndex: {
    footer: '4999',
    lists: '3999',
    listsDesktop: '2998',
    detail: '2999',
    topBar: '1999',
    seemore: '999',
    alert: '9499',
    loading: '8999',
    imgZoomUp: '7999',
  },
};

const theme = {
  light: {
    font: '#000000',
    background: '#FFFFFF',
    primary: '#FCEA10',
    secondary: '#212121',
    middleGary: '#989898',
    ...global,
  },
  dark: {
    font: '#FFFFFF',
    background: '#000000',
    primary: '#FCEA10',
    secondary: '#212121',
    middleGary: '#989898',
    ...global,
  },
};

export default theme;

// export const FONT_EXTRA_LIGHT = 'graphik-extra-light, noto-sans-extra-light';
// export const FONT_LIGHT = 'graphik-light, noto-sans-light';
// export const FONT_SEMIBOLD = 'graphik-semibold, noto-sans-semibold';
