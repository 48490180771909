/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import { call, takeLatest, fork, put, select } from 'redux-saga/effects';
import qs from 'qs';

import env from 'config/env';
import { detectIsMobile, getFormatPosition } from 'core/helper/util';
import { baseFetch } from 'core/api';
import { globalAction } from 'core/global';
import { audioAction } from 'core/audio';
import mapAction from './action';

export function* fetchSelectedMap({ payload }) {
  const { lang, slug, initData } = payload;
  const { latitude, longitude } = initData;
  let apiUrl = '/soundmap/more';
  const specifyPos = getFormatPosition();
  const currentCenter = specifyPos || { latitude, longitude };

  const mapAudioList = initData.sounds.map((sound) => {
    if (env.isIframe) {
      sound.podcast = sound.m3u8_uri;
    }
    return sound;
  });

  if (env.runDev === 'local') {
    apiUrl = `${apiUrl}?testing=${env.localApiKey}&lang=${lang}`;
  }

  try {
    const postData = env.isGroups ? { clot: slug } : {};
    const { data } = yield call(baseFetch, {
      method: 'post',
      url: apiUrl,
      data: qs.stringify(postData),
    });

    if (data.code === 1) {
      const { subset } = data.data;

      const mapOptions = subset.map((item) => {
        const newItem = { ...item };

        newItem.name = newItem.title;
        newItem.value = newItem.id;

        return newItem;
      });

      initData.mapPlanOptions = mapOptions;

      yield put(mapAction.setLayoutData(initData));
      yield put(mapAction.setAudioList(mapAudioList));
      yield put(
        mapAction.setMapCenter({
          latitude: currentCenter.latitude,
          longitude: currentCenter.longitude,
        })
      );

      const currentMap = env.isGroups
        ? mapOptions.find((obj) => obj.slug === initData.params.pid)
        : mapOptions.find((obj) => obj.id === initData.params.pid);

      if (currentMap) {
        yield put(mapAction.setSelectedMap(currentMap.id));
      }
    } else {
      yield put(
        globalAction.setAlert({
          theme: 'error',
          message: 'No Data Found',
          autoClose: 10000,
        })
      );
    }
  } catch (error) {
    yield put(
      globalAction.setAlert({
        theme: 'error',
        message: error.message,
        autoClose: 10000,
      })
    );
  }
}

export function* fetchSelectedDetail({ payload }) {
  const { audioObj, playing = false } = payload;
  const { selectedDetail, audioList } = yield select((state) => state.map);
  const { playingAudio, fetchedAudios } = yield select((state) => state.audio);
  const { latitude, longitude, id } = audioObj;
  // open detail & play in the same time
  if (playing) {
    if (playingAudio === audioObj.podcast) {
      yield put(
        audioAction.setPlayerState(
          fetchedAudios.find((audio) => audio.podcast === audioObj.podcast),
          'pause'
        )
      );
    } else {
      yield put(audioAction.playAfterFetch(audioObj.podcast));
    }
  }
  if (id !== selectedDetail.id) {
    let apiUrl = '/soundtale/one';

    if (env.runDev === 'local') {
      const { language } = yield select((state) => state.global);

      apiUrl = `${apiUrl}?testing=${env.localApiKey}&lang=${language}`;
    }

    yield put({ type: globalAction.FETCH_REQUEST });
    yield put(audioAction.fetchAudio(audioObj));

    try {
      const data = qs.stringify({ pid: id });
      const res = yield call(baseFetch, {
        method: 'post',
        url: apiUrl,
        data,
      });

      if (res.data.code === 1) {
        const detailData = res.data.data;
        const searchParams = new URLSearchParams(window.location.search);
        const iframeParam = searchParams.get('iframe');

        if (iframeParam === 'yes') {
          detailData.podcast = audioList.find(
            (list) => list.id === id
          ).m3u8_uri;
        }

        yield put({
          type: mapAction.SET_DETAIL_INFO,
          payload: detailData,
        });
        yield put(mapAction.setMapCenter({ latitude, longitude }));
        yield put(mapAction.toggleDetail(true));
        // yield put(globalAction.sendGtagEvent('playlist_tryto', 'thread', id));

        if (detectIsMobile(true)) {
          yield put(mapAction.toogleList(false));
        }
      } else {
        yield put(
          globalAction.setAlert({
            theme: 'error',
            message: 'No Data Found',
            autoClose: 10000,
          })
        );
      }
    } catch (error) {
      yield put(
        globalAction.setAlert({
          theme: 'error',
          message: error.message,
          autoClose: 10000,
        })
      );
    } finally {
      yield put({ type: globalAction.FETCH_FINISH });
    }
  }
}

export function* toggleDetail({ payload }) {
  if (!payload) {
    yield put({
      type: mapAction.SET_DETAIL_INFO,
      payload: {},
    });
  }
  yield put({ type: mapAction.SET_DETAIL_STATE, payload });
}

export function* changeMap({ payload }) {
  const { selectedId, navigate } = payload;
  const {
    layoutData: { id, mapPlanOptions, params: { lang, slug } = {} },
  } = yield select((state) => state.map);

  if (id !== selectedId) {
    const selectedMap = mapPlanOptions.find((obj) => obj.value === selectedId);

    yield put(globalAction.sendGtagEvent('tryto', 'related', selectedMap.name));
    // when is groups, selectedMap.slug is pid!!!
    navigate(
      env.isGroups
        ? `/${lang ? `${lang}/` : ''}groups/${slug}/${selectedMap.slug}${
            env.isIframe ? `?iframe=yes` : ''
          }`
        : `/${lang ? `${lang}/` : ''}soundmap/${selectedId}/${
            selectedMap.slug
          }${env.isIframe ? `?iframe=yes` : ''}`
    );
    window.location.reload();
  }
}

//-------------------------------------
//  WATCHERS
//-------------------------------------

export function* watchFetchSelectedMap() {
  yield takeLatest(mapAction.FETCH_SELECTED_MAP, fetchSelectedMap);
}

export function* watchFetchSelectedDetail() {
  yield takeLatest(mapAction.FETCH_SELECTED_DETAIL, fetchSelectedDetail);
}

export function* watchToggleDetail() {
  yield takeLatest(mapAction.TOGGLE_DETAIL, toggleDetail);
}

export function* watchChangeMap() {
  yield takeLatest(mapAction.CHANGE_MAP, changeMap);
}

//-------------------------------------
//  ROOT
//-------------------------------------

const root = [
  fork(watchFetchSelectedMap),
  fork(watchFetchSelectedDetail),
  fork(watchToggleDetail),
  fork(watchChangeMap),
];

export default root;
