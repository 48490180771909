import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { useDispatch, useSelector } from 'react-redux';
import Color from 'color';

import env from 'config/env';
import { mapAction } from 'core/map';
import { globalAction } from 'core/global';

import { LinkBtn } from 'components/global/Button';

function MarkerMark({ className, data }) {
  const dispatch = useDispatch();
  const {
    showDetail,
    mapCenter,
    layoutData: { hitsLevel = [] } = {},
  } = useSelector((state) => state.map);
  const [size, setSize] = useState(hitsLevel.length ? hitsLevel[0].width : 10);

  const handleClick = () => {
    dispatch(globalAction.sendGtagEvent('location-tryto', 'related', data.id));
    dispatch(mapAction.fetchSelectedDetail(data));
  };

  const switchSize = (num) => {
    let currentSize = size;
    const levelArray = [...hitsLevel].sort((a, b) => a.limit - b.limit);

    for (let i = 0; i < levelArray.length; i += 1) {
      const currentLevel = levelArray[i];

      if (currentLevel.limit > num) {
        currentSize = currentLevel.width;

        break;
      }
    }

    if (currentSize !== size) {
      setSize(currentSize);
    }
  };

  useEffect(() => {
    if (hitsLevel.length) {
      switchSize(data.hits);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.hits]);

  return (
    <Wrap
      className={`${className}${
        showDetail &&
        mapCenter.lat === Number(data.latitude) &&
        mapCenter.lng === Number(data.longitude)
          ? ' active'
          : ''
      }`}
      size={size}
      onClick={handleClick}
    >
      <Icon />
    </Wrap>
  );
}

MarkerMark.propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape().isRequired,
};

MarkerMark.defaultProps = { className: '' };

export default MarkerMark;

const Icon = styled.i`
  display: block;
  width: 0;
  height: 0;
`;

const Wrap = styled(LinkBtn)`
  position: relative;
  width: ${({ size }) => size / 5}em;
  height: ${({ size }) => size / 5}em;
  transform: translate(
    ${({ size }) => -(size - 4)}px,
    ${({ size }) => -(size - 4)}px
  );
  border-radius: 100%;
  background-color: ${({ theme }) => Color(theme.primary).alpha(0.7).string()};
  &.active {
    &::after {
      position: absolute;
      content: '';
      bottom: calc(50% + 2px);
      left: calc(50% - 8px);
      width: 16px;
      height: 22px;
      background-image: url('${env.routerBaseName}/asset/image/icon/pin.svg');
    }
  }
`;
