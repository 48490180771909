import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import Color from 'color';
import { useSelector } from 'react-redux';

import env from 'config/env';

import AudioBar from 'container/layout/Information/AudioBar';
import Detail from 'container/layout/Information/Detail';
import Carousel from 'container/layout/Information/Carousel';
// import Marker from 'container/GoogleMap/Marker';
import Image from 'components/global/Image';
import ImgZoomUp from 'components/global/ImgZoomUp';

function Information() {
  const {
    showDetail,
    miniDetail,
    audioList,
    selectedDetail: { podcast, pics = [] } = {},
  } = useSelector((state) => state.map);
  const { onload } = useSelector((state) => state.global);
  const [currentDetail, setCurrentDetail] = useState({});
  const [imageZoomUp, setImageZoomUp] = useState({});

  const handleClose = () => setImageZoomUp({});

  const handleClick = (imgObj) => setImageZoomUp(imgObj);

  useEffect(() => {
    const selectedAudio = audioList.find((audio) => audio.podcast === podcast);

    setCurrentDetail(selectedAudio);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [podcast]);

  return (
    <>
      <Wrap
        className={`${onload ? ' j-onload' : ''}${miniDetail ? ' j-mini' : ''}${
          showDetail ? ' active' : ''
        }`}
      >
        {/* selectedDetail ? <SelectedMarker data={selectedDetail} /> : null */}

        <Content>
          <Row>
            <Detail />

            {pics.length ? (
              <Carousel className={miniDetail ? 'j-hide' : ''}>
                {pics.map((obj, index) => (
                  <ClickImage
                    onClick={() => handleClick(obj)}
                    key={`${obj.id}${obj.pic}`}
                    src={
                      env.runDev === 'local'
                        ? 'https://via.placeholder.com/240x180/FCEA10/000000'
                        : obj.pic
                    }
                    alt={obj.title}
                    type="cover"
                  />
                ))}
              </Carousel>
            ) : null}
          </Row>

          <AudioBar audioData={currentDetail} />
        </Content>
      </Wrap>

      <ImgZoomUp
        url={imageZoomUp.pic}
        title={imageZoomUp.title}
        description={imageZoomUp.info}
        onClose={handleClose}
      />
    </>
  );
}

Information.propTypes = {};

Information.defaultProps = {};

export default Information;

const Row = styled.div`
  @media (orientation: portrait) {
    //豎屏CSS
    height: 360px;
    overflow-y: auto;
  }
  @media (orientation: landscape) {
    //橫屏CSS
  }
  @media (min-width: ${({ theme }) => theme.breakpoint.lg}) {
    display: flex;
    margin-bottom: 16px;
    & > div {
      &:last-child {
        width: 60%;
      }
      &:first-child {
        min-height: 40%;
        width: 40%;
      }
    }
  }
`;

const ClickImage = styled(Image)`
  cursor: pointer;
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 20px;
  overflow: auto;
  z-index: 2;
  @media (min-width: ${({ theme }) => theme.breakpoint.lg}) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 24px 32px;
    background-color: ${({ theme }) =>
      Color(theme.background).alpha(0.7).string()};
    overflow: hidden;
  }
`;

// const SelectedMarker = styled(Marker)`
//   position: fixed;
//   left: 50%;
//   top: 35%;
//   z-index: 1;
//   @media (min-width: ${({ theme }) => theme.breakpoint.lg}) {
//     display: none;
//   }
// `;

const Wrap = styled.div`
  position: fixed;
  display: flex;
  align-items: flex-end;
  bottom: 40px;
  left: 0;
  width: 100%;
  padding: 8px 0 0;
  opacity: 0;
  pointer-events: none;
  transform: translateY(calc(100% + 40px));
  transition: transform 0.5s ease-in-out 1.3s, opacity 0.5s linear 1.3s,
    height ease-in 0.3s;
  background-color: ${({ theme }) =>
    Color(theme.background).alpha(0.7).string()};
  z-index: ${({ theme }) => theme.zIndex.detail};
  &.j-onload {
    opacity: 1;
    transform: translateY(0);
  }
  &.active {
    opacity: 1;
    pointer-events: auto;
  }
  &.j-mini {
    height: auto;
    ${Row} {
      height: auto;
    }
  }
  @media (orientation: landscape) {
    //橫屏CSS
    height: calc(100% - 40px);
  }
  @media (min-width: ${({ theme }) => theme.breakpoint.lg}) {
    max-height: 100vh;
    height: auto;
    background-color: transparent;
    &.active {
      pointer-events: none;
      ${Content} {
        pointer-events: auto;
      }
    }
    &.j-mini {
      ${Row} {
        margin-bottom: 4px;
        > div {
          width: 100%;
        }
      }
      ${Content} {
        overflow-y: hidden;
      }
    }
  }
`;
