import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useScript } from 'core/helper/hook';
import MapComponent from './map';

const GoogleMap = forwardRef(
  (
    {
      apiKey,
      libraries,
      children,
      loadingContent,
      idleContent,
      errorContent,
      mapMinHeight,
      containerProps,
      ...props
    },
    ref
  ) => {
    const status = useScript(
      apiKey
        ? {
            src: `https://maps.googleapis.com/maps/api/js?key=${apiKey}&callback=Function.prototype&libraries=${libraries?.join(
              ','
            )}`,
            attributes: { id: 'googleMapsApi' },
          }
        : {
            src: `https://maps.googleapis.com/maps/api/js?callback=Function.prototype&libraries=${libraries?.join(
              ','
            )}`,
            attributes: { id: 'googleMapsApi' },
          }
    );
    const statusComponent = () => {
      switch (status) {
        case 'ready':
          // eslint-disable-next-line react/jsx-props-no-spreading
          return <MapComponent {...props}>{children}</MapComponent>;

        case 'loading':
          return loadingContent;

        case 'idle':
          return idleContent;

        case 'error':
          return errorContent;

        default:
          return errorContent;
      }
    };

    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          overflow: 'hidden',
          position: 'relative',
          minHeight: mapMinHeight,
          // zIndex: '0',
        }}
        ref={ref}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...containerProps}
      >
        {statusComponent()}
      </div>
    );
  }
);

GoogleMap.defaultProps = {
  ...MapComponent.defaultProps,
  loadingContent: 'Google Maps is loading',
  idleContent: 'Google Maps is on idle',
  errorContent: 'Google Maps is on error',
  mapMinHeight: 'unset',
  apiKey: '',
  libraries: ['places', 'geometry'],
  containerProps: undefined,
};

GoogleMap.propTypes = {
  ...MapComponent.propTypes,
  /**
   * The Markers on the Map.
   */
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  /**
   * Content to be displayed while the map is loading.
   */
  loadingContent: PropTypes.node,
  /**
   * Content to be displayed while the map is idle.
   */
  idleContent: PropTypes.node,
  /**
   * Content to be displayed when there is an error loading the map.
   */
  errorContent: PropTypes.node,
  /**
   * The minimum height of the map.
   */
  mapMinHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Props to be passed to the container div.
   */
  containerProps: PropTypes.shape(),
};

export default GoogleMap;
