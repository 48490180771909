import styled, { css } from 'styled-components/macro';

const Button = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 12px;
  height: 28px;
  padding: 0 12px;
  border-radius: 16px;
  letter-spacing: 0.5px;
  white-space: nowrap;
  cursor: pointer;
  text-decoration: none;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  &:disabled {
    color: ${({ theme }) => theme.font};
    background-color: ${({ theme }) => theme.disabled};
    border: 1px solid ${({ theme }) => theme.disabled};
    &:hover {
      color: ${({ theme }) => theme.font};
      background-color: ${({ theme }) => theme.disabled};
      border: 1px solid ${({ theme }) => theme.disabled};
      cursor: not-allowed;
    }
  }
  ${({ fill, color = ({ theme }) => theme.font }) =>
    fill === 'full'
      ? css`
          color: ${({ theme }) => theme.background};
          background-color: ${color};
          border: 1px solid ${color};
          /* &:hover {
            color: ${color};
            background-color: ${({ theme }) => theme.background};
          } */
        `
      : css`
          color: ${color};
          border: 1px solid ${color};
          background-color: transparent;
          /* &:hover {
            color: #fff;
            background-color: ${color};
          } */
        `}
  ${({ size }) =>
    size === 'large'
      ? css`
          font-size: 18px;
          line-height: 25px;
          letter-spacing: 1px;
          font-weight: normal;
          height: 60px;
          padding: 0 40px;
          border-radius: 30px;
        `
      : null}
  ${({ size }) =>
    size === 'small'
      ? css`
          height: 24px;
          padding: 0 20px;
        `
      : null}
`;

export const LinkBtn = styled.button.attrs({ type: 'button' })`
  color: inherit;
  border: 0;
  padding: 0;
  background-color: transparent;
  text-decoration: ${({ underline }) => (underline ? 'underline' : 'nont')};
  cursor: pointer;
`;

export default Button;
